import React, { useContext, useEffect } from "react";
import { AuthContext } from "../helpers/authContext";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import LeftSideBar from "./LeftSideBar";
import Footer from "./Footer";
import Breadcrumbs from "./layouts/Breadcrumbs";
import Greetings from "./dashboard-elements/Greetings";
import TotalPRStatus from "./dashboard-elements/graphs/TotalPRStatus";
import SpinnerUI from "./layouts/SpinnerUI";

export default function Index() {
  const { accessToken, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken, navigate]);

  return (
    <div id="layout-wrapper">
      <Navbar />
      <LeftSideBar />
      <div className="main-content">
        <div className="page-content">
          <div className="page-title-box">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <div className="page-title">
                    <h4>Dashboard</h4>
                    <Breadcrumbs />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="page-content-wrapper">
              <div className="row">
                <Greetings />
              </div>
              <div className="row">
                {currentUser ? <TotalPRStatus /> : <SpinnerUI />}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
