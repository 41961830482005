import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../helpers/authContext";

import SpinnerUI from "../layouts/SpinnerUI";
import API from "../../helpers/baseApi";
import { renderValidationErrors } from "../../helpers/validation";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const { accessToken, setAccessToken, setCurrentUser } =
    useContext(AuthContext);

  useEffect(() => {
    if (accessToken) navigate("/");
  }, [accessToken, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setLoading(true);
    const userData = { email, password };

    try {
      //Request CSRF token cookie
      await API.get("/api/csrf-cookie").then(async () => {
        const response = await API.post("/api/user/login", userData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Custom-Header": "value",
          },
        });
        localStorage.setItem(
          "accessToken",
          JSON.stringify(response.data.access_token)
        );
        setAccessToken(response.data.access_token);
        setCurrentUser(response.data.user);
        setLoading(false);
        setEmail("");
        setPassword("");
        navigate("/");
      });
    } catch (error) {
      setLoading(false);
      setErrors(error?.response?.data.errors);
    }
  };

  return (
    <div className="home-center">
      <div className="home-desc-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="px-2 py-3">
                    <div className="text-center">
                      <Link to="/index">
                        <img
                          src="https://www.aexis.com.my/public/images/logo.png"
                          height={22}
                          alt="logo"
                        />
                      </Link>
                      <h5 className="text-primary mb-2 mt-4">Welcome Back !</h5>
                      <p className="text-muted">Log in to continue</p>
                    </div>
                    <form
                      className="form-horizontal mt-4 pt-2 custom-label"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="mb-3">
                        <label htmlFor="email">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {renderValidationErrors(errors, "email")}
                      <div className="mb-3">
                        <label htmlFor="userpassword">Password</label>
                        <input
                          type="password"
                          className="form-control"
                          id="userpassword"
                          placeholder="Enter password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {renderValidationErrors(errors, "password")}
                      <div className="mt-3">
                        {renderValidationErrors(errors, "errors")}
                      </div>
                      <div>
                        {loading ? (
                          <SpinnerUI />
                        ) : (
                          <button
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        )}
                      </div>                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
