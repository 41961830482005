import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import SignatureCanvas from "react-signature-canvas";

export default function AddSignModal({
  showModal,
  closeModal,
  uploadedSignature,
  callingFrom,
}) {
  const [signatureURL, setSignatureURL] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [empty, setEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const signCanvas = useRef({});

  const clearCanvas = () => {
    signCanvas.current.clear();
  };

  const getSignature = () => {
    if (!signCanvas.current.isEmpty()) {
      setLoading(true);
      setEmpty(false);
      const url = signCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      setSignatureURL(url);
      uploadedSignature(url, additionalNotes);
      closeModal(url);
    } else {
      setEmpty(true);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Signature</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <SignatureCanvas
                ref={signCanvas}
                penColor="black"
                canvasProps={{
                  width: "230px",
                  height: "130px",
                  className: "signature-canvas",
                  style: { borderStyle: "solid" },
                }}
              />
            </div>
          </div>

          {empty ? (
            <div className="row mt-3">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  Signature is required
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {!callingFrom === "create_form" ? (
          <div className="row">
            <div className="col">
              <Form.Label>Additional Notes (Optional)</Form.Label>
              <Form.Control
                name="additional_notes"
                type="text"
                onChange={(event) => {
                  setAdditionalNotes(event.target.value);
                }}
              />
            </div>
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button variant="danger" onClick={clearCanvas}>
          Clear
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            getSignature();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
