import React, {
  useState,
  useEffect,
  useContext,
} from "react";
import Chart from "react-apexcharts";
import api from "../../../helpers/api";
import { AuthContext } from "../../../helpers/authContext";
import { useNavigate } from "react-router-dom";

export default function Monthly({ value }) {
  const { accessToken } = useContext(AuthContext);
  const [countPR, setCountPR] = useState([]);
  const navigate = useNavigate();

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken]);

  // get number of pr based on status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStatus = await api.getPRStatusMonthly(accessToken);
        setCountPR(responseStatus.data);
        console.log(responseStatus.data);

        // const test = await api.getPRStatusGraph(accessToken);
        // console.log(test.data);

        const responseCount = await api.countPRByCurrentYear(accessToken);
        value(responseCount.data);        
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, []);


  const seriesData = Object.entries(countPR).map(([status, counts]) => ({
    name: status,
    data: Object.values(counts)
  }));

  console.log(seriesData);

  const stackedColumnChart = {
    options: {
      chart: {
        id: "stacked-column-chart",
        height: 380,
        type: "bar",
        stacked: true,
        toolbar: { show: false },
        zoom: { enabled: true },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "20%", endingShape: "rounded" },
    },
    dataLabels: { enabled: true },
    series: seriesData,
    colors: ["#525ce5", "#edf1f5"],
    legend: { show: false },
    fill: { opacity: 1 },
  };

  return (
    <Chart
      charttype="stackedColumn"
      options={stackedColumnChart.options}
      series={stackedColumnChart.series}
      type={stackedColumnChart.options.chart.type}
      height={stackedColumnChart.options.chart.height}
    />
  );
}
