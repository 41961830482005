import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import Form from "react-bootstrap/Form";
import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import api from "../helpers/api";
import Navbar from "./Navbar";
import LeftSideBar from "./LeftSideBar";
import Footer from "./Footer";
import { AuthContext } from "../helpers/authContext";
import Breadcrumbs from "./layouts/Breadcrumbs";
import SpinnerUI from "./layouts/SpinnerUI";
import { showErrorAlert } from "./ui/SweetAlert";
import API from "../helpers/baseApi";
import Swal from "sweetalert2";

export default function Table() {
  const { accessToken, currentUser, theme } = useContext(AuthContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [radioLogTicket, setRadioLogTicket] = useState("no");
  const [filterPrRefNo, setFilterPrRefNo] = useState("");
  const [filterProjectName, setFilterProjectName] = useState("");
  const [filterLogTicket, setFilterLogTicket] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterStatus, setFilterStatus] = useState("Pending_Program_Manager");
  const [filteredData, setFilteredData] = useState([]);
  const [draftData, setDraftData] = useState([]);
  const [filterPaymentTerm, setFilterPaymentTerm] = useState("");
  const [filterDateCreated, setFilterDateCreated] = useState("");
  const [defaultData, setDefaultData] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]); //api
  const [project, setProject] = useState([]); //api
  const [category, setCategory] = useState([]); //api
  const [status, setStatus] = useState([]); //api
  const [logTicket, setLogTicket] = useState([]); //api
  const [loading, setLoading] = useState(true);

  // convert YYYY-MM-DD to DD-MM-YYYY
  const formatDate = (unformatted_date) => {
    const old_date = unformatted_date;
    const date = new Date(old_date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // concat
    const formatted_date = `${day}-${month}-${year}`;
    return formatted_date;
  };

  // remove underscore - pr status
  const removeUnderscore = (str) => {
    return str?.replace(/_/g, " ");
  };

  const columns = [
    {
      name: "PR Ref. No.",
      selector: (row) => row.purchase_pr_ref_no,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.purchase_pr_ref_no}</div>,
    },
    {
      name: "Project Name",
      selector: (row) => row.project_name,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.project_name}</div>,
    },
    {
      name: "Category",
      selector: (row) => row.category_name,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.category_name}</div>,
    },
    {
      name: "Status",
      selector: (row) => removeUnderscore(row.status_name),
      sortable: true,
      cell: (row) => (
        <div className="word-wrap">{removeUnderscore(row.status_name)}</div>
      ),
    },
    {
      name: "Date Created",
      selector: (row) => formatDate(row.purchase_created_at),
      sortable: true,
    },
    {
      name: "Log Ticket",
      selector: (row) => row.log_ticket_number,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.log_ticket_number}</div>,
      width: "120px",
    },
    {
      name: "Payment Term",
      selector: (row) => row.payment_term_name,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.payment_term_name}</div>,
    },
  ];

  const columnsDraft = [
    {
      name: "#",
      selector: (row) => row.index,
      sortable: true,
      cell: (row, index) => <div>{index + 1}</div>,
      width: '55px',
    },
    {
      name: "Date Created",
      selector: (row) => row.purchase_created_at,
      sortable: true,      
    },      
  ];

  //multi filters - gather combination of filters
  const applyFilters = () => {
    let filteredData = data;

    // Filter project name
    if (filterProjectName !== "") {
      filteredData = filteredData.filter((item) =>
        item.project_name.includes(filterProjectName)
      );
    }

    // Filter category
    if (filterCategory !== "") {
      filteredData = filteredData.filter((item) =>
        item.category_name.includes(filterCategory)
      );
    }

    // Filter status
    if (filterStatus !== "") {
      filteredData = filteredData.filter((item) =>
        item.status_name.includes(filterStatus)
      );
    }

    // Filter date created
    if (filterDateCreated !== "") {
      const filteredDate = new Date(filterDateCreated); //new input date - Mon Jan 29 2024 08:00:00 GMT+0800 (Malaysia Time)
      filteredData = filteredData.filter((item) => {
        const dbDate = new Date(item.purchase_created_at); //old DB date - YYYY-MM-DD HH:MM:SS //new db date - Mon Jan 29 2024 08:00:00 GMT+0800 (Malaysia Time)
        return dbDate.toDateString() === filteredDate.toDateString();
      });
    }

    // Filter log ticket
    if (filterLogTicket !== "") {
      filteredData = filteredData.filter((item) => {
        return (
          item.log_ticket_number &&
          item.log_ticket_number.includes(filterLogTicket)
        );
      });
    }

    // Filter payment term
    if (filterPaymentTerm !== "") {
      filteredData = filteredData.filter(
        (item) => item.payment_term_name === filterPaymentTerm
      );
    }
    return filteredData;
  };

  // invoke apply filters to render filtered data
  useEffect(() => {
    const result = applyFilters();
    setFilteredData(result);
  }, [
    filterProjectName,
    filterPaymentTerm,
    filterDateCreated,
    filterLogTicket,
    filterStatus,
    filterCategory,
  ]);

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken]);

  //get all PR + by default display only pending PR
  useEffect(() => {
    const fetchData = async () => {
      if (currentUser) {    
        try {
          let responseAllPR;
          // for requestor
          if (currentUser.user_position_id === 4) {
            responseAllPR = await api.getAllSubmittedPR(
              accessToken,
              currentUser.user_id
            );
            setDraftData(responseAllPR.data?.draft_pr);
            // for PM/SM/CEO
          } else {
            const position_id = currentUser.user_position_id;
            responseAllPR = await api.getAllPR(accessToken, position_id);
          }
          setData(responseAllPR.data?.submitted_pr);
          const pendingData = responseAllPR.data?.submitted_pr.filter(
            (item) => item.status_name === "Pending_Program_Manager"
          );
          setFilteredData(pendingData);
          setDefaultData(pendingData);
          setLoading(false);
        } catch (error) {
          showErrorAlert(
            "Oops...",
            "Something went wrong! Please try again",
            "error"
          );
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [currentUser]);

  // api - get payment term & project name (for dropdown)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseStatus = await api.getStatusPR(accessToken);
        setStatus(responseStatus.data);

        const responsePaymentTerm = await api.getPaymentTerm(accessToken);
        setPaymentTerm(responsePaymentTerm.data);

        const responseProjectName = await api.getProjectName(accessToken);
        setProject(responseProjectName.data);

        const responseCategory = await api.getCategory(accessToken);
        setCategory(responseCategory.data);
      } catch (error) {
        showErrorAlert(
          "Oops...",
          "Something went wrong! Please try again",
          "error"
        );
      }
    };
    fetchData();
  }, []);

  // filter status
  const handleFilterStatus = (e) => {
    const value = e.target.value;
    setFilterStatus(value);
  };

  // filter category
  const handleFilterCategory = (e) => {
    const value = e.target.value;
    setFilterCategory(value);
  };

  // filter payment term
  const handleFilterPaymentTerm = (e) => {
    const value = e.target.value;
    setFilterPaymentTerm(value);
  };

  // filter project name
  const handleFilterProjectName = (e) => {
    const value = e.target.value;
    setFilterProjectName(value);
  };

  // filter log ticket
  const handleFilterLogTicket = (e) => {
    const value = e.target.value;
    setFilterLogTicket(value);
  };

  // filter date created
  const handleFilterDateCreated = (e) => {
    const value = e.target.value; //input date - YYYY-MM-DD
    setFilterDateCreated(value);
  };

  //clear all filters
  const handleReset = () => {
    setFilterPrRefNo("");
    setFilterProjectName("");
    setFilterLogTicket("");
    setFilterStatus("Pending_Program_Manager");
    setFilterDateCreated("");
    setFilterPaymentTerm("");
    setFilterCategory("");
    setRadioLogTicket("no");

    // display back default data (PR status new)
    setFilteredData(defaultData);
  };

  //handle row click
  const handleRowClicked = async (row) => {
    const { purchase_id } = row; //yg ni kena send kat dynamic page, page accept ni sebagai param
    navigate(`/pr/${purchase_id}`, { state: { user_id: currentUser.user_id } });
  };

  // display when there is no pr
  const noDataComponent = () => {
    return (
      <div className="col d-flex align-items-center justify-content-center">
        <div className="col-xl-3">
          <p className="text-center">No records found!</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <LeftSideBar />
        <div className="main-content">
          <div className="page-content">
            <div className="page-title-box">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="page-title">
                      <h4>List of Purchase Requests</h4>
                      <Breadcrumbs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="page-content-wrapper">
                <div className="row">
                  <div className="col-sm">
                    <div className="card">
                      <div className="card-body">
                        <h5 className="mb-3">Submitted PR</h5>
                        <div className="row align-items-center">
                          <div className="col-sm">
                            <div className="pr-status-filter">
                              <Form.Group as={Col} md="5">
                                <Form.Label className="custom-label">
                                  Filter by project status
                                </Form.Label>
                                <Form.Select
                                  className="custom-form-field"
                                  value={filterStatus}
                                  onChange={handleFilterStatus}
                                >
                                  <option value="">Select status</option>
                                  {status.map((statusName) => (
                                    <option
                                      key={statusName.status_id}
                                      value={statusName.status_name}
                                    >
                                      {removeUnderscore(statusName.status_name)}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Form.Group>
                            </div>
                          </div>
                          <div
                            className="col-sm-3"
                            // style={{ textAlign: "right" }}
                          >
                            <div className="float-end d-none d-sm-block">
                              <Button
                                variant="success"
                                onClick={() => {
                                  navigate("/createPR");
                                }}
                              >
                                Create PR
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <p className="card-header">
                          <a
                            className="d-block"
                            data-bs-toggle="collapse"
                            href="#collapse-card"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapse-card"
                          >
                            <span>Filter by others</span>
                            <i className="dripicons-chevron-down float-end"></i>
                          </a>
                        </p>
                        <div id="collapse-card" className="collapse p-3 border">
                          <div className="row mb-3">
                            <div className="col-sm mb-2">
                              <Form.Label className="custom-label">
                                Project Name
                              </Form.Label>
                              <Form.Select
                                className="custom-form-field"
                                name="project_name"
                                onChange={handleFilterProjectName}
                                value={filterProjectName}
                              >
                                <option value="">Select project</option>
                                {project.map((projectName) => (
                                  <option
                                    key={projectName.project_id}
                                    value={projectName.project_name}
                                  >
                                    {projectName.project_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="col-sm mb-2">
                              <Form.Label className="custom-label">
                                Category
                              </Form.Label>
                              <Form.Select
                                className="custom-form-field"
                                name="category"
                                onChange={handleFilterCategory}
                                value={filterCategory}
                              >
                                <option value="">Select category</option>
                                {category.map((category) => (
                                  <option
                                    key={category.category_id}
                                    value={category.category_name}
                                  >
                                    {category.category_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-sm mb-2">
                              <Form.Label className="custom-label">
                                Payment Term
                              </Form.Label>
                              <Form.Select
                                className="custom-form-field"
                                name="payment_term"
                                onChange={handleFilterPaymentTerm}
                                value={filterPaymentTerm}
                              >
                                <option value="">Select payment term</option>
                                {paymentTerm.map((payment_term) => (
                                  <option
                                    key={payment_term.payment_term_id}
                                    value={payment_term.payment_term_name}
                                  >
                                    {payment_term.payment_term_name}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="col-sm mb-2">
                              <Form.Label className="custom-label">
                                Date Created
                              </Form.Label>
                              <Form.Control
                                className="custom-form-field"
                                type="date"
                                format={"YYYY/MM/DD"}
                                name="date_created"
                                onChange={handleFilterDateCreated}
                                value={filterDateCreated}
                              />
                            </div>
                          </div>

                          <div className="row mb-3">
                            <div className="col-sm">
                              <Form.Label className="custom-label">
                                Log Ticket
                              </Form.Label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  value="no"
                                  onChange={(e) =>
                                    setRadioLogTicket(e.target.value)
                                  }
                                  checked={radioLogTicket === "no"}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault1"
                                >
                                  No
                                </label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input custom-form-field"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault2"
                                  onChange={(e) =>
                                    setRadioLogTicket(e.target.value)
                                  }
                                  checked={radioLogTicket === "yes"}
                                  value="yes"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexRadioDefault2"
                                >
                                  Yes
                                </label>
                              </div>
                            </div>

                            {radioLogTicket === "yes" && (
                              <div className="col-sm">
                                <Form.Label className="custom-label">
                                  Log Ticket Number
                                </Form.Label>
                                <Form.Control
                                  className="custom-form-field"
                                  type="text"
                                  name="log_ticket_no"
                                  onChange={handleFilterLogTicket}
                                  value={filterLogTicket}
                                />
                              </div>
                            )}
                          </div>

                          <div className="mb-0">
                            <button
                              className="btn btn-primary"
                              type="reset"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        {loading ? (
                          <SpinnerUI />
                        ) : (
                          <DataTable
                            columns={columns}
                            data={filteredData}
                            pagination
                            fixedHeader="true"
                            fixedHeaderScrollHeight="300px"
                            highlightOnHover
                            defaultSortAsc={true}
                            onRowClicked={handleRowClicked}
                            noDataComponent={noDataComponent()}
                            theme={theme === "dark" ? "custom-dark" : "light"}
                            customStyles={{
                              rows: {
                                style: {
                                  cursor: "pointer",
                                },
                              },
                            }}
                          />
                        )}
                      </div>
                    </div>

                    {/* display draft PR */}
                    {currentUser?.user_position_id === 4 ? (
                      <div className="card">
                        <div className="card-body">
                          <h5 className="mb-3">Draft PR</h5>
                          {loading ? (
                            <SpinnerUI />
                          ) : (
                            <DataTable
                              columns={columnsDraft}
                              data={draftData}
                              pagination
                              fixedHeader="true"
                              fixedHeaderScrollHeight="300px"                              
                              highlightOnHover
                              defaultSortAsc={true}           
                              onRowClicked={handleRowClicked}            
                              noDataComponent={noDataComponent()}
                              theme={theme === "dark" ? "custom-dark" : "light"}
                              customStyles={{
                                rows: {
                                  style: {
                                    cursor: "pointer",
                                  },
                                },
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
