import React, { useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";

export default function RejectPRModal({
  showModal,
  closeModal,
  uploadedRejectedPRAdditionalNotes,
}) {
  const [rejectedPRAdditionalNotes, setRejectedPRAdditionalNotes] =
    useState("");
  const [empty, setEmpty] = useState(false);

  const getAdditionalNotes = () => {
    if (rejectedPRAdditionalNotes) {
      setEmpty(false);
      uploadedRejectedPRAdditionalNotes(rejectedPRAdditionalNotes);
      closeModal();
    } else {
      setEmpty(true);
    }
  };

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Reject Purchase Request</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div className="row">
            <div className="col">
              <Form.Label>Reason of rejecting</Form.Label>
            </div>
            <div className="col">
              <Form.Control
                name="reason_reject"
                type="text"
                required
                onChange={(event) => {
                  setRejectedPRAdditionalNotes(event.target.value);
                }}
              />
            </div>
          </div>
          {empty ? (
            <div className="row mt-3">
              <div className="col">
                <div className="alert alert-danger" role="alert">
                  Reason of rejecting is required
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            getAdditionalNotes();
          }}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
