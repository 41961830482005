import axios from "axios";
import { BASE_URL } from "./url";
import { getConfig } from "./config";

const api = {

    // ********************** GET DATA **********************************
    getData: async (accessToken) => {
        return axios.get(`${BASE_URL}/getData`, getConfig(accessToken));
    },
    getPaymentTerm: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPaymentTerm`, getConfig(accessToken));
    },
    getSupplier: async (accessToken) => {
        return axios.get(`${BASE_URL}/getSupplier`, getConfig(accessToken));
    },
    getItem: async (accessToken) => {
        return axios.get(`${BASE_URL}/getItem`, getConfig(accessToken));
    },    
    getProjectName: async (accessToken) => {
        return axios.get(`${BASE_URL}/getProject`, getConfig(accessToken));
    },
    getCategory: async (accessToken) => {
        return axios.get(`${BASE_URL}/getCategory`, getConfig(accessToken));
    },
    getStatusPR: async (accessToken) => {
        return axios.get(`${BASE_URL}/getStatusPR`, getConfig(accessToken));
    },
    getLogTicket: async (accessToken) => {
        return axios.get(`${BASE_URL}/getLogTicket`, getConfig(accessToken));
    },
    getAllPR: async (accessToken, position_id) => {
        return axios.get(`${BASE_URL}/getAllPR/${position_id}`, getConfig(accessToken));
    },
    getAllSubmittedPR: async (accessToken, user_id) => {
        return axios.get(`${BASE_URL}/getAllSubmittedPR/${user_id}`, getConfig(accessToken));
    },
    getSpecificPR: async (pr_ref_no, accessToken) => {
        return axios.get(`${BASE_URL}/getSpecificPR/${pr_ref_no}`, getConfig(accessToken));
    }, 
    
    // ********************** GRAPH **********************************
    getPRStatusDaily: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPRStatusDaily`, getConfig(accessToken));
    },    
    getPRStatusMonthly: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPRStatusMonthly`, getConfig(accessToken));
    },
    getPRStatusYearly: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPRStatusYearly`, getConfig(accessToken));
    }, 
    countPRByCurrentYear: async (accessToken) => {
        return axios.get(`${BASE_URL}/countPRByCurrentYear`, getConfig(accessToken));
    }, 
    countAllPR: async (accessToken) => {
        return axios.get(`${BASE_URL}/countAllPR`, getConfig(accessToken));
    }, 
    getPRStatusGraph: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPRStatusGraph`, getConfig(accessToken));
    }, 
    
    // ********************** PR FORM **********************************
    createPR: async (formData, userData, accessToken) => {
        return axios.post(`${BASE_URL}/createPurchase`, formData, userData, getConfig(accessToken));
    },
    deletePR: async (id, accessToken) => {
        return axios.put(`${BASE_URL}/deletePR/${id}`, getConfig(accessToken));
    },
    editPR: async (id, accessToken) => {
        return axios.put(`${BASE_URL}/editPR/${id}`, getConfig(accessToken));
    },

    // ********************** USER **********************************
    forgotPassword: async (email) => {
        return axios.post(`${BASE_URL}/forgot-password`, email);
    },
    resetPassword: async (data) => {
        return axios.post(`${BASE_URL}/reset-password`, data);
    },

    // ********************** ADMIN **********************************
    getPosition: async (accessToken) => {
        return axios.get(`${BASE_URL}/getPosition`, getConfig(accessToken));
    },
    getProgramManager: async (accessToken) => {
        return axios.get(`${BASE_URL}/getProgramManager`, getConfig(accessToken));
    },
    getRole: async (accessToken) => {
        return axios.get(`${BASE_URL}/admin/getRole`, getConfig(accessToken));
    },   
    getStatus: async (accessToken) => {
        return axios.get(`${BASE_URL}/admin/getStatus`, getConfig(accessToken));
    }, 
    getAllUsers: async (accessToken) => {
        return axios.get(`${BASE_URL}/getAllUsers`, getConfig(accessToken));
    },
    getAllProjects: async (accessToken) => {
        return axios.get(`${BASE_URL}/getAllProjects`, getConfig(accessToken));
    },
};

export default api;