import React from 'react'
import { Button, Modal } from 'react-bootstrap';

export default function ViewUploadedFileModal({ showModal, closeModal, fileURL }) {
  const FileComponent = ({}) => {
    // Get the file extension
    const fileExtension = fileURL.split(".").pop().toLowerCase();

    // Determine the file type based on the extension
    let fileType;
    if (fileExtension === "pdf") {
      fileType = "pdf";
    } else if (["jpg", "jpeg", "png", "gif", "bmp"].includes(fileExtension)) {
      fileType = "image";
    } else {
      fileType = "other";
    }

    // Render the file based on its type
    let fileContent;
    if (fileType === "pdf") {
      fileContent = (
        <embed
          src={fileURL}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      );
    } else if (fileType === "image") {
      fileContent = <img src={fileURL} alt="File" />;
    } else {
      fileContent = (
        <div>
          <p>File type not supported.</p>
          <a href={fileURL} download>
            Download File
          </a>
        </div>
      );
    }

    return <div>{fileContent}</div>;
  };

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static" centered size="lg" fullscreen>
      <Modal.Header>
        <Modal.Title>Uploaded File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className='modal-body-div'> */}
          <FileComponent />
        {/* </div>         */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>        
      </Modal.Footer>
    </Modal>
  );
}
