export const dark_styles = {
  //styles for React-Select components
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#283247",
    color: "white",
    boxShadow: state.isFocused ? "0 0 0 1px white" : "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#564ab1" : "#283247",
    color: "white",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
};
