import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import api from "../../helpers/api";
import Select from "react-select";
import * as yup from "yup";
import { dark_styles } from "../../assets/js/react_select_style";
import { AuthContext } from "../../helpers/authContext";
import { showErrorAlert } from "../ui/SweetAlert";
import SpinnerUI from "../layouts/SpinnerUI";

export default function ProjectModal({
  showModal,
  closeModal,
  onSubmit,
  initialValues,
  token,
  action,
}) {
  const [programManager, setProgramManager] = useState([]);
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProgramManager = await api.getProgramManager(token);
        setProgramManager(responseProgramManager.data);
      } catch (error) {
        showErrorAlert(
          "Oops...",
          "Something went wrong! Please try again",
          "error"
        );
      }
    };
    fetchData();
  }, [token]);

  // list of program managers
  const loadOptionsProgramManager = programManager.map((program_manager) => ({
    value: program_manager.user_id,
    label: program_manager.user_fullname,
  }));

  // handle loading
  const handleLoading = () => {
    setLoading(true);
  };

  // validation schema Yup
  const validationSchema = yup.object().shape({
    project_name: yup.string().required(),
    program_manager: yup.string().required(),
    start_date: yup.string().required(),
    end_date: yup.string().required(),
    project_description: yup.string().required(),
  });

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {action === "add" ? "Add New Project" : "Edit Project Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <SpinnerUI />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleLoading();
              onSubmit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <div className="row">
                  <div className="col mb-3">
                    <Form.Label>Project Name</Form.Label>
                    <Form.Control
                      className="custom-form-field"
                      name="project_name"
                      type="text"
                      onChange={handleChange}
                      value={values.project_name || ""}
                    />
                    {touched.project_name && errors.project_name ? (
                      <div className="error-message">
                        Project name is required
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <Form.Label>Program Manager</Form.Label>
                    <Select
                      name="program_manager"
                      onChange={(option) => {
                        setFieldValue("program_manager", option.value);
                      }}
                      options={loadOptionsProgramManager}
                      placeholder="Select program manager"
                      value={
                        loadOptionsProgramManager.find(
                          (option) => option.value === values.program_manager
                        ) || null
                      }
                      styles={theme === "dark" ? dark_styles : ""}
                    />
                    {touched.program_manager && errors.program_manager ? (
                      <div className="error-message">
                        Program manager is required
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-3">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      className="custom-form-field"
                      name="start_date"
                      type="date"
                      onChange={handleChange}
                      value={values.start_date || ""}
                    />
                    {touched.start_date && errors.start_date ? (
                      <div className="error-message">
                        Start date is required
                      </div>
                    ) : null}
                  </div>
                  <div className="col mb-3">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      className="custom-form-field"
                      name="end_date"
                      type="date"
                      onChange={handleChange}
                      value={values.end_date || ""}
                    />
                    {touched.end_date && errors.end_date ? (
                      <div className="error-message">End date is required</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-3">
                    <Form.Label>Project Description</Form.Label>
                    <textarea
                      name="project_description"
                      className={
                        touched.project_description &&
                        errors.project_description
                          ? "error form-control custom-form-field"
                          : "form-control custom-form-field"
                      }
                      rows="2"
                      onChange={handleChange}
                      value={values.project_description || ""}
                    ></textarea>
                    {touched.project_description &&
                    errors.project_description ? (
                      <div className="error-message">
                        Project description is required
                      </div>
                    ) : null}
                  </div>
                </div>
                <Modal.Footer style={{ padding: "0" }}>
                  <Button
                    variant="secondary"
                    onClick={closeModal}
                    className="mt-3"
                    style={{ marginRight: "10px" }}
                  >
                    Cancel
                  </Button>
                  <Button className="mt-3" type="submit">
                    Submit!
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal.Body>
    </Modal>
  );
}
