import React, {
    useState,
    useEffect,
    useContext,
  } from "react";
  import Chart from "react-apexcharts";
  import api from "../../../helpers/api";
  import { AuthContext } from "../../../helpers/authContext";
  import { useNavigate } from "react-router-dom";
  
  export default function Yearly({ value }) {
    const { accessToken } = useContext(AuthContext);
    const [countPR, setCountPR] = useState([]);
    const navigate = useNavigate();
  
    //check if has access token
    useEffect(() => {
      if (!accessToken) navigate("/auth-login");
    }, [accessToken]);
  
    // get number of pr based on status
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.getPRStatusYearly(accessToken);
          setCountPR(response.data);
   
          const responseCount = await api.countPRByCurrentYear(accessToken);
          value(responseCount.data);    
        } catch (error) {
          console.log("Error:", error);
        }
      };
  
      fetchData();
    }, []);


    const setXAxis = () => {
        const years = Object.keys(countPR).reduce((acc, status) => {
            Object.keys(countPR[status].year).forEach((year) => {
                if(!acc.includes(year)){
                    acc.push(year);
                }
            });
            return acc;
        }, []);
        years.sort();
        return years;
    }

    const setSeriesData = () => {
        if (!countPR) return [];
        
        const seriesData = [];
        Object.keys(countPR).forEach((status) => {
            const data = setXAxis().map((year) => countPR[status].year[year] || 0);
            seriesData.push({name: status, data});
        });
        return seriesData;
    }
  
    const stackedColumnChart = {
      options: {
        chart: {
          id: "stacked-column-chart",
          height: 380,
          type: "bar",
          stacked: true,
          toolbar: { show: false },
          zoom: { enabled: true },
        },
        xaxis: {
          categories: setXAxis(),
        },
      },
      plotOptions: {
        bar: { horizontal: false, columnWidth: "20%", endingShape: "rounded" },
      },
      dataLabels: { enabled: true },
      series: setSeriesData(),
      colors: ["#525ce5", "#edf1f5"],
      legend: { show: false },
      fill: { opacity: 1 },
    };
  
    return (
      <Chart
        charttype="stackedColumn"
        options={stackedColumnChart.options}
        series={stackedColumnChart.series}
        type={stackedColumnChart.options.chart.type}
        height={stackedColumnChart.options.chart.height}
      />
    );
  }
  