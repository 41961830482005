import React, { useContext } from "react";
import { AuthContext } from "../../helpers/authContext";
import Lottie from "lottie-react";
import greetingsAnimation from "../../assets/lottie/Greetings.json";
import Clock from "../layouts/Clock";

export default function Greetings() {
  const { currentUser } = useContext(AuthContext);

  return (
      <div className="col">
        <div className="card-greetings">
          <div className="card-body-greetings">
            <div className="row align-items-center">
              <div className="col-xl-8">
                {currentUser && currentUser.user_fullname && (
                  <>
                    <h1 className="greetings-title mb-2">
                      Hello <span style={{color: "#525ce5"}}>{currentUser.user_fullname}</span>
                    </h1>
                    <p>Today is <Clock /></p>
                    <p>Have a nice day!</p>
                  </>
                )}
              </div>
              <div className="col-xl-4">
                <Lottie animationData={greetingsAnimation} />
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
