import React, { useContext, useEffect, useState } from "react";
import { Formik, FieldArray, useFormikContext } from "formik";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import api from "../../helpers/api";
import { AuthContext } from "../../helpers/authContext";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { showErrorAlert } from "../ui/SweetAlert";
import { dark_styles } from "../../assets/js/react_select_style";

const FormPR = ({
  initialValues,
  onSubmit,
  editType,
  submitFormData,
  formMode,
}) => {
  const { accessToken, theme, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [project, setProject] = useState([]);
  const [category, setCategory] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [displaySupplierInputs, setDisplaySupplierInputs] = useState(false);

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken, navigate]);

  //get all project, payment term, category, users, item & supplier - dropdown options
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProjectName = await api.getProjectName(accessToken);
        setProject(responseProjectName.data);

        const responsePaymentTerm = await api.getPaymentTerm(accessToken);
        setPaymentTerm(responsePaymentTerm.data);

        const responseCategory = await api.getCategory(accessToken);
        setCategory(responseCategory.data);

        const responseSupplier = await api.getSupplier(accessToken);
        setSupplierList(responseSupplier.data);

        const responseItem = await api.getItem(accessToken);
        setItemList(responseItem.data);
      } catch (error) {
        showErrorAlert(
          "Oops...",
          "Something went wrong! Please try again",
          "error"
        );
      }
    };
    fetchData();
  }, [accessToken]);

  // searchable dropdown - for category
  const loadOptionsCategory = category.map((category) => ({
    value: category.category_id,
    label: category.category_name,
  }));

  // searchable dropdown - for project
  const loadOptionsProject = project.map((project) => ({
    value: project.project_id,
    label: project.project_name,
  }));

  // searchable dropdown - for payment
  const loadOptionsPaymentTerm = paymentTerm.map((paymentTerm) => ({
    value: paymentTerm.payment_term_id,
    label: paymentTerm.payment_term_name,
  }));

  // searchable dropdown - for item
  const loadOptionsItemAdd = filteredItems.map((item) => ({
    value: item.item_description,
    label: item.item_description,
    id: item.item_id,
  }));

  // searchable dropdown - for item
  const loadOptionsItemEdit = itemList.map((item) => ({
    value: item.item_description,
    label: item.item_description,
  }));

  // searchable dropdown - for supplier name
  const loadOptionsSupplier = supplierList.map((supplier) => ({
    value: supplier.supplier_name,
    label: supplier.supplier_name,
  }));

  // calculate sum of all items total prices
  const sum = (suppliers) => {
    return suppliers.reduce((total, supplier) => {
      // For each supplier, sum up the total_price of all items
      return (
        total +
        (supplier.items
          ? supplier.items.reduce(
              (acc, item) => acc + (Number(item.total_price) || 0),
              0
            )
          : 0)
      );
    }, 0);
  };

  // validation schema Yup
  const validationSchema = yup.object().shape({
    category_id: yup.string().required(),
    project_id: yup.string().required(),
    payment_term_id: yup.string().required(),
    radio_hospital_repair: yup.string().required(),
    suppliers: yup.array().of(
      yup.object().shape({
        supplier_name: yup.string().required(),
        type: yup.string().required(),
        contact_person: yup.string().when([], {
          is: (val) => val && val.length > 0,
          then: yup.string().required(),
        }),
        phone_number: yup.string().when([], {
          is: (val) => val && val.length > 0,
          then: yup.string().required(),
        }),
        email: yup.string().when([], {
          is: (val) => val && val.length > 0,
          then: yup.string().required(),
        }),
        address: yup.string().when([], {
          is: (val) => val && val.length > 0,
          then: yup.string().required(),
        }),
        items: yup.array().of(
          yup.object().shape({
            description: yup.string().required(),
            quantity: yup.number().required(),
            unit_price: yup.number().required(),
          })
        ),
      })
    ),
    reason: yup.string().required(),
    radio_log_ticket: yup.string().required(),
    log_ticket_number: yup.mixed().when("radio_log_ticket", {
      is: (value) => value === "yes",
      then: () => yup.string().required(),
      otherwise: () => yup.string().nullable(),
    }),
    log_ticket_file: yup.mixed().when("radio_log_ticket", {
      is: (value) => value === "yes",
      then: () => yup.mixed().required("At least 1 file"),
      otherwise: () => yup.mixed().nullable(),
    }),
    quotation: yup
      .array()
      .required("Quotations are required")
      .min(1, "At least 1 file"),
  });

  // save as draft
  const handleSave = (values) => {
    const submissionData = {
      ...values,
      total_all: sum(values.suppliers),
      form_type: "draft",
    };
    // edit rejected / draft PR and save to draft - no need signature
    submitFormData(submissionData, "");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={(values) => {
        // submit
        const submissionData = {
          ...values,
          total_all: sum(values.suppliers),
          form_type: "submit",
        };
        if (editType === "rejected") {
          // edit rejected PR - no need signature again
          submitFormData(submissionData, "");
        } else {
          // edit draft PR and submit - need signature
          onSubmit(submissionData);
        }
      }}
    >
      {({ values, handleSubmit, errors }) => {
        return (
          <Form onSubmit={handleSubmit} noValidate>
            {/* card - purchase basic details */}
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <h5>Purchase Request Details</h5>
                </div>
                <BasicFields
                  loadOptionsCategory={loadOptionsCategory}
                  loadOptionsPaymentTerm={loadOptionsPaymentTerm}
                  loadOptionsProject={loadOptionsProject}
                  setSelectedCategory={setSelectedCategory}
                  selectedCategory={selectedCategory}
                  style={theme === "dark" ? dark_styles : ""}
                />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <h5>Items & Suppliers Details</h5>
                </div>
                <FieldArray
                  name="suppliers"
                  render={({ push: pushSupp, remove: removeSupp }) => (
                    <div className="row mb-3">
                      {values.suppliers.map((supplier, indexSupplier) => (
                        <div key={indexSupplier}>
                          <ItemFields
                            sum={sum}
                            indexSupplier={indexSupplier}
                            supplierList={supplierList}
                            supplier={supplier}
                            itemList={itemList}
                            loadOptionsSupplier={loadOptionsSupplier}
                            setFilteredItems={setFilteredItems}
                            setDisplaySupplierInputs={setDisplaySupplierInputs}
                            loadOptionsItemAdd={loadOptionsItemAdd}
                            loadOptionsItemEdit={loadOptionsItemEdit}
                            formMode={formMode}
                            removeSupp={removeSupp}
                            pushSupp={pushSupp}
                            style={theme === "dark" ? dark_styles : ""}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                ></FieldArray>
                {/* total all */}
                <div className="row">
                  <div className="col-sm-5">
                    <InputGroup className="mb-3">
                      <InputGroup.Text>RM</InputGroup.Text>
                      <Form.Control
                        className="custom-form-field"
                        name="total_all"
                        type="text"
                        value={sum(values.suppliers)}
                        disabled
                      />
                      <InputGroup.Text>.00</InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>
              </div>
            </div>

            {/* Justification */}
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <h5>Justification</h5>
                </div>
                <JustificationComponent/>
                <br />
                {editType === "rejected" ? null : (
                  <Button
                    style={{ marginRight: "10px" }}
                    type="button"
                    variant="secondary"
                    onClick={() => handleSave(values)}
                  >
                    Save as draft
                  </Button>
                )}

                <Button type="submit">Submit</Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormPR;

// Item Component
const ItemFields = ({
  indexSupplier,
  supplierList,
  supplier,
  itemList,
  loadOptionsSupplier,
  setFilteredItems,
  setDisplaySupplierInputs,
  loadOptionsItemAdd,
  loadOptionsItemEdit,
  formMode,
  removeSupp,
  pushSupp,
  style,
}) => {
  const { values, setFieldValue, handleChange, errors } = useFormikContext();

  // calculate total price of 1 item
  useEffect(() => {
    const calcTotalPrice = values.suppliers.map((supp, suppIndex) =>
      supp.items.map((item, itemIndex) => {
        const unitPrice = parseFloat(item.unit_price) || 0;
        const quantity = parseFloat(item.quantity) || 0;
        const totalPrice = quantity * unitPrice;
        setFieldValue(
          `suppliers.${suppIndex}.items.${itemIndex}.total_price`,
          totalPrice
        );
      })
    );
  }, [values.suppliers]);

  // autofill supplier details - for existing supplier
  const handleSupplierChange = async (value, index, setFieldValue, isNew) => {
    setFilteredItems([]);
    setDisplaySupplierInputs(true); //display other supplier inputs

    if (isNew) {
      setFieldValue(`suppliers.${indexSupplier}.is_new_supplier`, isNew);
      setFieldValue(`suppliers.${indexSupplier}.supplier_id`, "");
      setFieldValue(`suppliers.${indexSupplier}.type`, "");
      setFieldValue(`suppliers.${indexSupplier}.contact_person`, "");
      setFieldValue(`suppliers.${indexSupplier}.phone_number`, "");
      setFieldValue(`suppliers.${indexSupplier}.email`, "");
      setFieldValue(`suppliers.${indexSupplier}.address`, "");
      setFilteredItems([]);
    } else {
      //filter supplier - to get the selected supplier's data only
      const filteredSupplier = supplierList.filter((supplier) =>
        supplier.supplier_name.includes(value)
      );

      //for existing supplier - not for newly created supplier
      const supplierID = filteredSupplier[0].supplier_id;
      const filteredItems = itemList.filter(
        (item) => item.item_supplier_id === supplierID
      ); //use === not includes
      setFilteredItems(filteredItems); // have [0], only 1 index

      // autofill all values to formik & display at form fields
      setFieldValue(
        `suppliers.${index}.supplier_id`,
        filteredSupplier[0].supplier_id
      );
      setFieldValue(
        `suppliers.${index}.type`,
        filteredSupplier[0].supplier_type
      );
      setFieldValue(
        `suppliers.${index}.contact_person`,
        filteredSupplier[0].supplier_contact_person
      );
      setFieldValue(
        `suppliers.${index}.phone_number`,
        filteredSupplier[0].supplier_phone_number
      );
      setFieldValue(
        `suppliers.${index}.email`,
        filteredSupplier[0].supplier_email
      );
      setFieldValue(
        `suppliers.${index}.address`,
        filteredSupplier[0].supplier_address
      );
      setFieldValue(`suppliers.${index}.is_new_supplier`, false);
    }
  };

  return (
    <div
      id="card-item-and-supplier"
      className="border p-3 mb-3 mt-3 custom-label"
    >
      {/* section supplier ***************************** */}
      <div id="section-supplier">
        <div className="row">
          <div key={indexSupplier}>
            <div className="row mb-3">
              <h6 style={{ textDecorationLine: "underline" }}>
                Supplier {indexSupplier + 1} Details
              </h6>
            </div>
            <div className="row">
              <Form.Label className="float-start">Supplier Name</Form.Label>
              <div className="col mb-4">
                <CreatableSelect
                  name={`suppliers.${indexSupplier}.supplier_name`}
                  onChange={(opt) => {
                    setFieldValue(
                      `suppliers.${indexSupplier}.supplier_name`,
                      opt.value
                    );
                    handleSupplierChange(
                      opt.value,
                      indexSupplier,
                      setFieldValue,
                      opt.__isNew__
                    );
                  }}
                  options={loadOptionsSupplier}
                  placeholder="Select / Create a supplier"
                  value={loadOptionsSupplier.find(
                    (option) => option.value === supplier.supplier_name
                  )}
                  styles={style}
                />
                {errors.supplier &&
                  errors.supplier[indexSupplier] &&
                  errors.supplier[indexSupplier].supplier_name && (
                    <div className="error-message">
                      Supplier name is required
                    </div>
                  )}
              </div>
            </div>

            {supplier.supplier_name ? (
              <div className="hidden-supplier-inputs">
                <div className="row">
                  <div className="col">
                    <Form.Label className="float-start">Type</Form.Label>
                  </div>
                </div>
                <div className="row">
                  <div className="col mb-4">
                    <Form.Select
                      name={`suppliers.${indexSupplier}.type`}
                      className={
                        errors.suppliers &&
                        errors.suppliers[indexSupplier] &&
                        errors.suppliers[indexSupplier].type
                          ? "error custom-form-field"
                          : "custom-form-field"
                      }
                      value={supplier.type || ""}
                      onChange={handleChange}
                    >
                      <option value="online">
                        Online Shop (Shopee/Lazada/etc)
                      </option>
                      <option value="physical">Physical Shop</option>
                    </Form.Select>
                    {errors.suppliers &&
                      errors.suppliers[indexSupplier] &&
                      errors.suppliers[indexSupplier].type && (
                        <div className="error-message">Select shop type</div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg mb-4">
                    <Form.Label className="float-start">
                      Contact Person
                    </Form.Label>
                    <Form.Control
                      name={`suppliers.${indexSupplier}.contact_person`}
                      className={
                        errors.suppliers &&
                        errors.suppliers[indexSupplier] &&
                        errors.suppliers[indexSupplier].contact_person
                          ? "error custom-form-field"
                          : "custom-form-field"
                      }
                      type="text"
                      onChange={handleChange}
                      value={supplier.contact_person || ""}
                    />
                    {errors.suppliers &&
                      errors.suppliers[indexSupplier] &&
                      errors.suppliers[indexSupplier].contact_person && (
                        <div className="error-message">
                          Contact person is required
                        </div>
                      )}
                  </div>
                  <div className="col-lg mb-4">
                    <Form.Label className="float-start">Email</Form.Label>
                    <Form.Control
                      name={`items.${indexSupplier}.email`}
                      className={
                        errors.suppliers &&
                        errors.suppliers[indexSupplier] &&
                        errors.suppliers[indexSupplier].email
                          ? "error custom-form-field"
                          : "custom-form-field"
                      }
                      type="email"
                      onChange={handleChange}
                      value={supplier.email || ""}
                    />
                    {errors.suppliers &&
                      errors.suppliers[indexSupplier] &&
                      errors.suppliers[indexSupplier].email && (
                        <div className="error-message">Email is required</div>
                      )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg mb-3">
                    <Form.Label className="float-start">Address</Form.Label>
                    <textarea
                      name={`suppliers.${indexSupplier}.address`}
                      className={
                        errors.suppliers &&
                        errors.suppliers[indexSupplier] &&
                        errors.suppliers[indexSupplier].address
                          ? "error form-control custom-form-field"
                          : "form-control custom-form-field"
                      }
                      rows="3"
                      onChange={handleChange}
                      value={supplier.address || ""}
                    ></textarea>
                    {errors.suppliers &&
                      errors.suppliers[indexSupplier] &&
                      errors.suppliers[indexSupplier].address && (
                        <div className="error-message">Address is required</div>
                      )}
                  </div>
                </div>
              </div>
            ) : null}

            {indexSupplier > 0 ? (
              <div className="col mb-3">
                <Button
                  variant="danger"
                  onClick={() => removeSupp(indexSupplier)}
                >
                  Remove supplier
                </Button>
              </div>
            ) : null}

            {/* array items */}
            {supplier.supplier_name ? (
              <FieldArray
                name={`suppliers.${indexSupplier}.items`}
                render={({ push: pushItem, remove: removeItem }) => (
                  <div className="mt-5">
                    {supplier.items &&
                      supplier.items.map((item, itemIndex) => (
                        <div key={itemIndex}>
                          <div className="row mb-3 mt-5">
                            <h6 style={{ textDecorationLine: "underline" }}>
                              Item {itemIndex + 1} Details
                            </h6>
                          </div>
                          <div className="row">
                            <div className="col-sm mb-3">
                              <div className="row">
                                <Form.Label className="float-start">
                                  Item Name / Description
                                </Form.Label>
                              </div>
                              <div className="row">
                                <CreatableSelect
                                  name={`suppliers.${indexSupplier}.items.${itemIndex}.description`}
                                  onChange={(opt) => {
                                    setFieldValue(
                                      `suppliers.${indexSupplier}.items.${itemIndex}.description`,
                                      opt.value
                                    );
                                    setFieldValue(
                                      `suppliers.${indexSupplier}.items.${itemIndex}.item_id`,
                                      opt.id
                                    );
                                  }}
                                  options={
                                    formMode === "adding"
                                      ? loadOptionsItemAdd
                                      : loadOptionsItemEdit
                                  }
                                  placeholder="Select / Create an item"
                                  value={
                                    formMode === "adding"
                                      ? loadOptionsItemAdd.find(
                                          (option) =>
                                            option.value === item.description
                                        )
                                      : loadOptionsItemEdit.find(
                                          (option) =>
                                            option.value === item.description
                                        )
                                  }
                                  styles={style}
                                />
                              </div>
                              {errors.suppliers &&
                                errors.suppliers[indexSupplier] &&
                                errors.suppliers[indexSupplier].items &&
                                errors.suppliers[indexSupplier].items[
                                  itemIndex
                                ] &&
                                errors.suppliers[indexSupplier].items[itemIndex]
                                  .description && (
                                  <div className="error-message">
                                    Item name / description is required
                                  </div>
                                )}
                            </div>
                            <div className="col-sm mb-3">
                              <Form.Label className="float-start">
                                Item Code
                                <span id="caption-justification">(if any)</span>
                              </Form.Label>
                              <Form.Control
                                className="custom-form-field"
                                name={`suppliers.${indexSupplier}.items.${itemIndex}.item_code`}
                                type="text"
                                value={item.item_code || ""}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="row">
                              <div className="col-sm mb-3">
                                <Form.Label className="float-start">
                                  Quantity
                                </Form.Label>
                                <Form.Control
                                  className={
                                    errors.suppliers &&
                                    errors.suppliers[indexSupplier] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ].quantity
                                      ? "error custom-form-field"
                                      : "custom-form-field"
                                  }
                                  name={`suppliers.${indexSupplier}.items.${itemIndex}.quantity`}
                                  type="number"
                                  value={item.quantity || ""}
                                  onChange={handleChange}
                                />
                                {errors.suppliers &&
                                  errors.suppliers[indexSupplier] &&
                                  errors.suppliers[indexSupplier].items &&
                                  errors.suppliers[indexSupplier].items[
                                    itemIndex
                                  ] &&
                                  errors.suppliers[indexSupplier].items[
                                    itemIndex
                                  ].quantity && (
                                    <div className="error-message">
                                      Quantity is required
                                    </div>
                                  )}
                              </div>
                              <div className="col-sm mb-3">
                                <Form.Label className="float-start">
                                  Unit Price (RM)
                                </Form.Label>
                                <Form.Control
                                  className={
                                    errors.suppliers &&
                                    errors.suppliers[indexSupplier] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ].unit_price
                                      ? "error custom-form-field"
                                      : "custom-form-field"
                                  }
                                  name={`suppliers.${indexSupplier}.items.${itemIndex}.unit_price`}
                                  type="number"
                                  onChange={handleChange}
                                  value={item.unit_price || ""}
                                />
                                {errors.suppliers &&
                                  errors.suppliers[indexSupplier] &&
                                  errors.suppliers[indexSupplier].items &&
                                  errors.suppliers[indexSupplier].items[
                                    itemIndex
                                  ] &&
                                  errors.suppliers[indexSupplier].items[
                                    itemIndex
                                  ].unit_price && (
                                    <div className="error-message">
                                      Unit price is required
                                    </div>
                                  )}
                              </div>
                              <div className="col-sm mb-3">
                                <Form.Label className="float-start">
                                  Total Price (RM)
                                </Form.Label>
                                <Form.Control
                                  className="custom-form-field"
                                  name={`suppliers.${indexSupplier}.items.${itemIndex}.total_price`}
                                  type="text"
                                  value={item.total_price || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm mb-3">
                                <Form.Label className="float-start">
                                  Claim Amount
                                  <span id="caption-justification">
                                    (if any)
                                  </span>
                                </Form.Label>
                                <textarea
                                  name={`suppliers.${indexSupplier}.items.${itemIndex}.claim`}
                                  className={
                                    errors.suppliers &&
                                    errors.suppliers[indexSupplier] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ] &&
                                    errors.suppliers[indexSupplier].items[
                                      itemIndex
                                    ].claim
                                      ? "error form-control custom-form-field"
                                      : "form-control custom-form-field"
                                  }
                                  rows="3"
                                  onChange={handleChange}
                                  value={item.claim || ""}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                          {itemIndex > 0 ? (
                            <div className="col mb-3">
                              <Button
                                variant="danger"
                                onClick={() => {                         
                                  removeItem(itemIndex);                                  
                                }}
                              >
                                Remove item
                              </Button>
                            </div>
                          ) : null}
                        </div>
                      ))}
                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <Button
                          onClick={() =>
                            pushItem({
                              item_id: "",
                              item_code: "",
                              description: "",
                              quantity: 0,
                              claim: "",
                              unit_price: 0,
                              total_price: 0,
                            })
                          }
                        >
                          Add Item
                        </Button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4 mb-3">
                        <Button
                          onClick={() =>
                            pushSupp({
                              supplier_id: "",
                              supplier_name: "",
                              type: "online",
                              contact_person: "",
                              phone_number: "",
                              email: "",
                              address: "",
                              items: [],
                            })
                          }
                        >
                          Add Supplier
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              ></FieldArray>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

// Basic PR Details Component
const BasicFields = ({
  loadOptionsCategory,
  loadOptionsPaymentTerm,
  loadOptionsProject,
  setSelectedCategory,
  selectedCategory,
  style,
}) => {
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext();

  return (
    <div id="section-basic" className="custom-label">
      <div className="row">
        <div className="col mb-4">
          <div className="row">
            <Form.Label className="float-start">PR Category</Form.Label>
          </div>
          <Select
            name="category_id"
            onChange={(selectedOption) => {
              setFieldValue("category_id", selectedOption.value);
              setSelectedCategory(selectedOption.value);
            }}
            options={loadOptionsCategory}
            placeholder="Select / Search a category"
            value={
              loadOptionsCategory.find(
                (option) => option.value === values.category_id
              ) || null
            }
            styles={style}
          />
          {touched.category_id && errors.category_id ? (
            <div className="error-message">Select a category</div>
          ) : null}
        </div>

        <div className="col mb-4">
          <div className="row">
            <Form.Label className="float-start">Project Name</Form.Label>
          </div>
          <Select
            name="project_id"
            onChange={(selectedOption) => {
              setFieldValue("project_id", selectedOption.value);
            }}
            options={loadOptionsProject}
            placeholder="Select / Search a project"
            value={
              loadOptionsProject.find(
                (option) => option.value === values.project_id
              ) || null
            }
            styles={style}
          />
          {touched.project_id && errors.project_id ? (
            <div className="error-message">Select a project</div>
          ) : null}
        </div>
      </div>

      {/* Request Repair radio -  only for IKN and HSGB */}
      {selectedCategory === 6 || selectedCategory === 8 ? (
        <>
          <div className="row">
            <div className="col-lg">
              <Form.Label className="float-start">Request Repair?</Form.Label>
              <br />
              <br />
              <div className="float-start mb-5">
                <Form.Check
                  name="radio_hospital_repair"
                  type="radio"
                  label="No"
                  value="no"
                  onChange={handleChange}
                  checked={values.radio_hospital_repair === "no"}
                />
                <Form.Check
                  name="radio_hospital_repair"
                  type="radio"
                  label="Yes"
                  value="yes"
                  onChange={handleChange}
                  checked={values.radio_hospital_repair === "yes"}
                />
                {touched.radio_hospital_repair &&
                errors.radio_hospital_repair ? (
                  <div className="error-message">Select one</div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}

      <div className="row">
        <div className="col mb-4">
          <div className="row">
            <Form.Label className="float-start">Payment Term</Form.Label>
          </div>
          <CreatableSelect
            name="payment_term_id"
            onChange={(opt) => {
              setFieldValue("payment_term_id", opt.value);
            }}
            options={loadOptionsPaymentTerm}
            placeholder="Select / Search a payment term"
            value={loadOptionsPaymentTerm.find(
              (option) => option.value === values.payment_term_id
            )}
            styles={style}
          />
          {touched.payment_term_id && errors.payment_term_id ? (
            <div className="error-message">Select a payment term</div>
          ) : null}
        </div>
        <div className="col mb-4">
          <Form.Label className="float-start">
            Estimate Delivery Date (EDD)
            <span id="caption-justification">(if any)</span>
          </Form.Label>
          <Form.Control
            className="custom-form-field"
            name="edd"
            type="date"
            onChange={handleChange}
            value={values.edd || ""}
          />
        </div>
      </div>
    </div>
  );
};

// Justification Component
const JustificationComponent = ({}) => {
  const { values, setFieldValue, handleChange, errors, touched, setValues } =
    useFormikContext();

  // remove uploaded files (existing files / newly uploaded)
  const handleRemoveFile = async (
    values,
    setValues,
    index,
    fileFrom,
    fileID
  ) => {
    //  if delete quotation file
    if (fileFrom === "quotation") {
      const updatedQuotation = [...values.quotation];
      const removedFile = updatedQuotation.splice(index, 1)[0];
      const updatedRemovedFileIds = [...values.removedQuotationIds];

      if (removedFile.isExisting) {
        updatedRemovedFileIds.push(removedFile.id); 
      }
      setValues({ ...values, quotation: updatedQuotation, removedQuotationIds: updatedRemovedFileIds });
      
    } else if (fileFrom === "log_ticket") {
      const updatedLogTicketFile = null;
      const removedLogTicketFileId = fileID;
      setValues({ ...values, log_ticket_file: updatedLogTicketFile, removedLogTicketFileId: removedLogTicketFileId });

    } else if (fileFrom === "other_file") {
      const updatedOtherFile = [...values.other_file];
      const removedFile = updatedOtherFile.splice(index, 1)[0];
      const updatedRemovedFileIds = [...values.removedOtherFileIds];
      
      if (removedFile.isExisting) {
        updatedRemovedFileIds.push(removedFile.id); 
      }
      setValues({ ...values, other_file: updatedOtherFile, removedOtherFileIds: updatedRemovedFileIds });
    }
  };

  return (
    <div className="section-justification custom-label">
      <div className="row mb-3">
        <div className="col-lg mb-4">
          <Form.Label className="float-start">Reason of Purchase</Form.Label>
          <textarea
            name="reason"
            className={
              touched.reason && errors.reason
                ? "error form-control custom-form-field"
                : "form-control custom-form-field"
            }
            rows="2"
            onChange={handleChange}
            value={values.reason || ""}
          ></textarea>
          {touched.reason && errors.reason ? (
            <div className="error-message">Give a reason</div>
          ) : null}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-5">
          <Form.Label className="float-start">Upload Quotations</Form.Label>
          <Form.Control
            className={
              touched.quotation && errors.quotation
                ? "error custom-form-field"
                : "custom-form-field"
            }
            type="file"
            multiple
            name="quotation"
            onChange={(event) => {
              const files = event.target.files;
              let uploadFiles = Array.from(files).map((file) => ({
                file: file,
                name: file.name,
                isExisting: false,
              }));
              let updatedFiles = [...values.quotation, ...uploadFiles];
              setFieldValue("quotation", updatedFiles);
            }}
          />
          {touched.quotation && errors.quotation ? (
            <div className="error-message">{errors.quotation}</div>
          ) : null}

          <div id="caption-form">
            Uploaded Quotations
            {values.quotation && values.quotation.length > 0 && (
              <>
                {values.quotation.map((file, index) => (
                  <li className="file-list" key={index}>
                    <div className="col-10">
                      <i className="mdi mdi-file-document"></i>
                      {file.name}
                    </div>
                    <div className="col">
                      <i
                        className="mdi mdi-delete"
                        onClick={() =>
                          handleRemoveFile(
                            values,
                            setValues,
                            index,
                            "quotation",
                            file.id,
                            setFieldValue
                          )
                        }
                      ></i>
                    </div>
                  </li>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="col-lg-6 mb-5">
          <Form.Label className="float-start">
            Upload Other Files <span id="caption-justification">(if any)</span>
          </Form.Label>
          <Form.Control
            className={
              touched.quotation && errors.quotation
                ? "error custom-form-field"
                : "custom-form-field"
            }
            type="file"
            multiple
            name="other_file"
            onChange={(event) => {
              const files = event.target.files;
              let uploadFiles = Array.from(files).map((file) => ({
                file: file,
                name: file.name,
                isExisting: false,
              }));
              let updatedFiles = [...values.other_file, ...uploadFiles];
              setFieldValue("other_file", updatedFiles);
            }}
          />
          {touched.other_file && errors.other_file ? (
            <div className="error-message">{errors.other_file}</div>
          ) : null}

          <div id="caption-form">
            Uploaded Files
            {values.other_file && values.other_file.length > 0 && (
              <>
                {values.other_file.map((file, index) => (
                  <li className="file-list" key={index}>
                    <div className="col-10">
                      <i className="mdi mdi-file-document"></i>
                      {file.name}
                    </div>
                    <div className="col">
                      <i
                        className="mdi mdi-delete"
                        onClick={() =>
                          handleRemoveFile(
                            values,
                            setValues,
                            index,
                            "other_file",
                            file.id,
                            setFieldValue
                          )
                        }
                      ></i>
                    </div>
                  </li>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Form.Label className="float-start">Have Log Ticket?</Form.Label>
          <br />
          <br />
          <div className="mb-5 float-start">
            <Form.Check
              name="radio_log_ticket"
              type="radio"
              label="No"
              value="no"
              onChange={(event) => {                
                const {name, value} = event.target;                      
                if(name === 'radio_log_ticket'){
                  if(value === 'no'){    
                    // for uploaded log ticket
                    if(values.log_ticket_file !== null && values.log_ticket_number !== null){
                      const removedLogTicketFileId = values.log_ticket_file[0].id;  
                      setValues({...values, log_ticket_file: null, log_ticket_number: null, radio_log_ticket: value, removedLogTicketFileId: removedLogTicketFileId});
                      // for no uploaded log ticket
                    }else{
                      setValues({...values, log_ticket_file: null, log_ticket_number: null, radio_log_ticket: value, removedLogTicketFileId: null});
                    }                                                
                  }
                }
              }}
              checked={values.radio_log_ticket === "no"}
            />
            <Form.Check
              name="radio_log_ticket"
              type="radio"
              label="Yes"
              value="yes"
              onChange={handleChange}
              checked={values.radio_log_ticket === "yes"}
            />
          </div>
        </div>
      </div>

      {values.radio_log_ticket === "yes" && (
        <div className="row">
          <div className="col-lg mb-3">
            <Form.Label className="float-start">Log Ticket Number</Form.Label>
            <Form.Control
              className={
                touched.log_ticket_number && errors.log_ticket_number
                  ? "error custom-form-field"
                  : "custom-form-field"
              }
              name="log_ticket_number"
              type="text"
              onChange={handleChange}
              value={values.log_ticket_number || ""}
            />
            {touched.log_ticket_number && errors.log_ticket_number ? (
              <div className="error-message">Log ticket number is required</div>
            ) : null}
          </div>
          <div className="col-lg mb-3">
            <Form.Label className="float-start">
              Upload Log Ticket{" "}
              <span id="caption-justification">(at least 1 log ticket)</span>
            </Form.Label>
            <Form.Control
              className={
                touched.log_ticket_file && errors.log_ticket_file
                  ? "error custom-form-field"
                  : "custom-form-field"
              }
              type="file"
              name="log_ticket_file"
              onChange={(event) => {
                const files = event.target.files;
                let uploadFiles = Array.from(files);                
                setFieldValue("log_ticket_file", uploadFiles);
              }}
              disabled={values.log_ticket_file !== null}
            />
            {touched.log_ticket_file && errors.log_ticket_file ? (
              <div className="error-message">{errors.log_ticket_file}</div>
            ) : null}

            <div className="mb-5" id="caption-form">
              Uploaded Log Ticket
              {values.log_ticket_file ? (
                <>
                  <li className="file-list">
                    <div className="col-10">
                      <i className="mdi mdi-file-document"></i>
                      {values.log_ticket_file[0].name}
                    </div>
                    <div className="col">
                      <i
                        className="mdi mdi-delete"
                        onClick={() =>
                          handleRemoveFile(
                            values,
                            setValues,
                            null,
                            "log_ticket",
                            values.log_ticket_file[0].id,
                            setFieldValue
                          )
                        }
                      ></i>
                    </div>
                  </li>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
