export const renderValidationErrors = (errors, field) => {
  return (
    errors?.[field]?.length &&
    errors[field].map((error, index) => {
      return (
        <div key={index} className="alert alert-danger" role="alert">
           {error}
        </div>
      );
    })
  );
};
