import axios from "axios";

let API = axios.create({
    baseURL: "https://prbackend.codeartisan.asia",
    withCredentials: true,
});

API.defaults.withCredentials = true;
API.defaults.withXSRFToken = true;

export default API;