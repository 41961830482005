import React, { useContext, useState } from "react";
import Swal from "sweetalert2";
import API from "../../helpers/baseApi";
import FormPR from "./FormPR";
import { AuthContext } from "../../helpers/authContext";
import { useNavigate } from "react-router-dom";
import { showErrorAlert } from "../ui/SweetAlert";
import Footer from "../Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import LeftSideBar from "../LeftSideBar";
import Navbar from "../Navbar";
import AddSignModal from "../ui/Modal/AddSignModal";
import SpinnerUI from "../layouts/SpinnerUI";

export default function CreatePR() {
  const formMode = "adding";
  const { accessToken, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showAddSignatureModal, setShowAddSignatureModal] = useState(false);
  const [values, setValues] = useState([]);
  const [uploadedSignature, setUploadedSignature] = useState("");
  const [loading, setLoading] = useState(false);
  const editType = 'create';
  const initialValues = {
    category_id: "",
    project_id: "",
    edd: "",
    payment_term_id: "",
    reason: "",
    total_all: 0,
    radio_hospital_repair: "no",
    radio_log_ticket: "no",
    signature_url: "",
    log_ticket_file: "",
    log_ticket_number: "",
    quotation: [],
    other_file: [],
    suppliers: [
      {
        supplier_id: "",
        supplier_name: "",
        type: "online",
        contact_person: "",
        phone_number: "",
        email: "",
        address: "",
        items: [
          {
            item_id: "",
            item_code: "",
            description: "",
            quantity: 0,
            claim: "",
            unit_price: 0,
            total_price: 0,
          },
        ],
      },
    ],
  };

  const handleCloseModal = (image_url) => {
    setShowAddSignatureModal(false);
    submitFormData(values, image_url);
  };

  const handleShowAddSignatureModal = () => {
    setShowAddSignatureModal(true);
  };

  const handleUploadedSignature = (image_url) => {
    setUploadedSignature(image_url);
  };

  const handleSubmit = async (values) => {
    Swal.fire({
      title: "Are you sure to submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, submit it!",
    }).then(async (willSubmit) => {
      if (willSubmit.isConfirmed) {
        handleShowAddSignatureModal();
        setValues(values);
        setLoading(true);
      }
    });
  };

  // submit formData
  const submitFormData = async (values, image_url) => {
    setLoading(true);
    const formData = new FormData();

    // normal input -> formData (no array / nested)
    formData.append("user_id", currentUser.user_id);
    formData.append("category_id", values.category_id);
    formData.append("reason", values.reason);
    formData.append("edd", values.edd);
    formData.append("project_id", values.project_id);
    formData.append("payment_term_id", values.payment_term_id);
    formData.append("total_all", values.total_all);
    formData.append("radio_hospital_repair", values.radio_hospital_repair);
    formData.append("signature_url", image_url);
    formData.append("log_ticket_number", values.log_ticket_number);
    formData.append("form_type", values.form_type);

    // quotation -> formData
    values.quotation.forEach((file, index) => {
      formData.append(`quotation[${index}]`, file.file);
    });

    // other related files -> formData
    values.other_file.forEach((file, index) => {
      formData.append(`other_file[${index}]`, file.file);
    });

    // log ticket -> formData
    formData.append("log_ticket_file", values.log_ticket_file);

    // suppliers -> formData
    if (values.suppliers) {
      // append suppliers
      values.suppliers.forEach((supplier, supplierIndex) => {
        for (let supplierKey in supplier) {
          formData.append(`supplier[${supplierIndex}][${supplierKey}]`, supplier[supplierKey]);
        }
        // append items
        if(supplier.items){
          supplier.items.forEach((item, itemIndex) => {
            for (let itemKey in item){
              formData.append(`supplier[${supplierIndex}][item][${itemIndex}][${itemKey}]`, item[itemKey]);
            }
          })
        }
      });
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      const response = await API.post("/api/createPurchase", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response) {
        setLoading(false);
        Swal.fire({
          title: "Submitted!",
          text: response.data.message,
          icon: "success",
        });
        navigate("/list-pr");
      }
    } catch (error) {
      setLoading(false);
      showErrorAlert(
        "Oops...",
        "Something went wrong! Please try again",
        "error"
      );
    }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <LeftSideBar />
        <div className="main-content">
          <div className="page-content">
            <div className="page-title-box">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="page-title">
                      <h4>Purchase Request Form</h4>
                      <Breadcrumbs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="page-content-wrapper">
                <div className="row">
                  {loading ? (
                    <div className="SpinnerUI-overlay">
                      <SpinnerUI />
                    </div>
                  ) : (
                    <div className="col-lg-12">
                      <FormPR
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        editType={editType}
                        submitFormData={submitFormData}
                        formMode={formMode}
                      />
                    </div>
                  )}
                  <AddSignModal
                    showModal={showAddSignatureModal}
                    closeModal={handleCloseModal}
                    uploadedSignature={handleUploadedSignature}
                    callingFrom="create_form"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
