import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import api from "../../helpers/api";
import Select from "react-select";
import * as yup from "yup";
import { AuthContext } from "../../helpers/authContext";
import { dark_styles } from "../../assets/js/react_select_style";
import { showErrorAlert } from "../ui/SweetAlert";
import SpinnerUI from "../layouts/SpinnerUI";

export default function FormModal({
  showModal,
  closeModal,
  onSubmit,
  initialValues,
  token,
  action,
}) {
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState([]);
  const [role, setRole] = useState([]);
  const [status, setStatus] = useState([]);
  const { theme } = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responsePosition = await api.getPosition(token);
        setPosition(responsePosition.data);

        const responseRole = await api.getRole(token);
        setRole(responseRole.data);

        const responseStatus = await api.getStatus(token);
        setStatus(responseStatus.data);
      } catch (error) {
        showErrorAlert(
          "Oops...",
          "Something went wrong! Please try again",
          "error"
        );
      }
    };
    fetchData();
  }, [token]);

  const loadOptionsPosition = position.map((position) => ({
    value: position.position_id,
    label: position.position_name,
  }));

  const loadOptionsRole = role.map((role) => ({
    value: role.role_id,
    label: role.role_name,
  }));

  const loadOptionsStatus = status.map((status) => ({
    value: status.account_status_id,
    label: status.account_status_name,
  }));

  // handle loading
  const handleLoading = () => {
    setLoading(true);
  }

  // validation schema Yup
  const validationSchema = yup.object().shape({
    email: yup.string().required(),
    password: yup.string().required(),
    fullname: yup.string().required(),
    position_id: yup.string().required(),
    job_role: yup.string().required(),
    company: yup.string().required(),
    role_id: yup.string().required(),
    status_id: yup.string().required(),
  });

  return (
    <Modal show={showModal} onHide={closeModal} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {action === "add" ? "Add New User" : "Edit User Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <SpinnerUI />
        ) : (
          <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleLoading();
            onSubmit(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <div className="row">
                <div className="col mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="email"
                    type="text"
                    onChange={handleChange}
                    value={values.email || ""}
                  />
                  {touched.email && errors.email ? (
                    <div className="error-message">Email is required</div>
                  ) : null}
                </div>
                <div className="col mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="password"
                    type="password"
                    onChange={handleChange}
                    value={values.password || ""}
                  />
                  {touched.password && errors.password ? (
                    <div className="error-message">Password is required</div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col mb-3">
                  <Form.Label>Fullname</Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="fullname"
                    type="text"
                    onChange={handleChange}
                    value={values.fullname || ""}
                  />
                  {touched.fullname && errors.fullname ? (
                    <div className="error-message">Fullname is required</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <Form.Label>Position</Form.Label>
                  <Select
                    name="position_id"
                    onChange={(option) => {
                      setFieldValue("position_id", option.value);
                    }}
                    options={loadOptionsPosition}
                    placeholder="Select user position"
                    value={
                      loadOptionsPosition.find(
                        (option) => option.value === values.position_id
                      ) || null
                    }
                    styles={theme === "dark" ? dark_styles : ""}
                  />
                  {touched.position_id && errors.position_id ? (
                    <div className="error-message">Position is required</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                <Form.Label>Job Role</Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="job_role"
                    type="text"
                    onChange={handleChange}
                    value={values.job_role || ""}
                  />
                  {errors.job_role ? (
                    <div className="error-message">Job role is required</div>
                  ) : null}                  
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <Form.Label>Company</Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="company"
                    type="text"
                    onChange={handleChange}
                    value={values.company || ""}
                  />
                  {touched.email && errors.email ? (
                    <div className="error-message">Company is required</div>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <Form.Label>
                    On-site Location{" "}
                    <span id="caption-justification">(if any)</span>
                  </Form.Label>
                  <Form.Control
                    className="custom-form-field"
                    name="on_site"
                    type="text"
                    onChange={handleChange}
                    value={values.on_site || ""}
                  />                  
                </div>
              </div>
              <div className="row">
                <div className="col mb-3">
                  <Form.Label>Role</Form.Label>
                  <Select
                    name="role_id"
                    onChange={(option) => {
                      setFieldValue("role_id", option.value);
                    }}
                    options={loadOptionsRole}
                    placeholder="Select user role"
                    value={
                      loadOptionsRole.find(
                        (option) => option.value === values.role_id
                      ) || null
                    }
                    styles={theme === "dark" ? dark_styles : ""}
                  />
                  {touched.role_id && errors.role_id ? (
                    <div className="error-message">Role is required</div>
                  ) : null}
                </div>
                <div className="col mb-3">
                  <Form.Label>Status</Form.Label>
                  <Select
                    name="status_id"
                    onChange={(option) => {
                      setFieldValue("status_id", option.value);
                    }}
                    options={loadOptionsStatus}
                    placeholder="Select user status"
                    value={
                      loadOptionsStatus.find(
                        (option) => option.value === values.status_id
                      ) || null
                    }
                    styles={theme === "dark" ? dark_styles : ""}
                  />
                  {touched.status_id && errors.status_id ? (
                    <div className="error-message">Status is required</div>
                  ) : null}
                </div>
              </div>
              <Modal.Footer style={{ padding: "0" }}>
                <Button
                  variant="secondary"
                  onClick={closeModal}
                  className="mt-3"
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button className="mt-3" type="submit">
                  Submit!
                </Button>
              </Modal.Footer>
            </Form>
          )}
          </Formik>
        )}        
      </Modal.Body>
    </Modal>
  );
}
