import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SpinnerUI from "../layouts/SpinnerUI";

import API from "../../helpers/baseApi";
import { renderValidationErrors } from "../../helpers/validation";

export default function ResetPassword() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [errors, setErrors] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({});
    setLoading(true);
    setStatus(null);

    const data = {email: email, password: password, password_confirmation: confirmPassword, token: token};

    try {
      await API.get("/api/csrf-cookie").then(async () => {           
        const response = await API.post('/api/reset-password', data);  
        setStatus(response.data.message);
        setLoading(false); 
        navigate("/auth-login");      
      });          
    } catch (error){
      setLoading(false); 
      setErrors(error.response.data.errors);  
    }    
  }

  return (
    <div className="home-center">
      <div className="home-desc-center">
        <div className="container">
          <div className="home-btn">
            <Link to="/" className="text-white router-link-active">
              <i className="fas fa-home h2" />
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="px-2 py-3">
                    <div className="text-center">
                      <Link to="/index">
                        <img
                          src="https://www.aexis.com.my/public/images/logo.png"
                          height={22}
                          alt="logo"
                        />
                      </Link>
                      <h5 className="text-primary mb-2 mt-4">Reset Password</h5>
                      <p className="text-muted">Re-Password with Morvin.</p>
                    </div>
                    {status && 
                    <div
                      className="alert alert-success text-center mb-4 mt-4 pt-2"
                      role="alert"
                    >
                      {status && <p>{status}</p>}
                    </div>
                    }                    
                    <form className="form-horizontal custom-label" onSubmit={(e) => handleSubmit(e)}>
                      <div className="mb-3">
                        <label htmlFor="useremail">Email</label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"                        
                          placeholder="Enter email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      {renderValidationErrors(errors, "email")}
                      <div className="mb-3">
                        <label htmlFor="useremail">New Password</label>
                        <input
                          type="password"
                          name="password"
                          className="form-control"                        
                          placeholder="Enter new password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      {renderValidationErrors(errors, "password")}
                      <div className="mb-3">
                        <label htmlFor="useremail">Confirm New Password</label>
                        <input
                          type="password"
                          name="confirm_password"
                          className="form-control"                        
                          placeholder="Enter new password again"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                      {renderValidationErrors(errors, "confirm_password")}
                      <div className="mt-3">
                        {renderValidationErrors(errors, "errors")}
                      </div>
                      <div className="row mb-0">
                        <div className="col-12 text-end">
                          {
                            loading ? <SpinnerUI/>
                            :
                            <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Reset Password
                          </button>
                          }                          
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center text-black">
                <p>
                  Remember It ?
                  <Link to="/auth-login" className="fw-bold text-purple">
                    {" "}
                    Sign In here
                  </Link>
                </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
