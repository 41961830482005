import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Index from "./Components/Index";
import Table from "./Components/Table";
import PurchaseDetails from "./Components/PurchaseDetails";
import Login from "./Components/auth/Login";
import { BASE_URL } from "./helpers/url";
import { AuthContext } from "./helpers/authContext";
import RecoverPassword from "./Components/auth/RecoverPassword";
import ResetPassword from "./Components/auth/ResetPassword";
import API from "./helpers/baseApi";
import CreatePR from "./Components/form/CreatePR";
import EditPR from "./Components/form/EditPR";
import UserManagement from "./Components/admin/UserManagement";
import useLocalStorage from "use-local-storage";
import ProjectManagement from "./Components/admin/ProjectManagement";

function App() {
  const [accessToken, setAccessToken] = useState(
    JSON.parse(localStorage.getItem("accessToken"))
  );
  const [currentUser, setCurrentUser] = useState(null);
  const [user_id, setUserId] = useState(null);
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', newTheme);
    setTheme(newTheme);
  }

useEffect(() => {
  const fetchCurrentUser = async () => {
    try {
      const response = await API.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setCurrentUser(response.data.user);
      setUserId(response.data.user.user_id);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("accessToken");
        setCurrentUser(null);
        setAccessToken("");
      }
      console.error("Error fetching current user:", error);
    }
  };
  if (accessToken) {
        fetchCurrentUser();
      }
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        setAccessToken,
        currentUser,
        setCurrentUser,
        theme, 
        toggleTheme,
      }}
    >
      <BrowserRouter>
      <div className="app">
        <Routes>
          <Route path="/" element={<Navigate to="/index" />} />
          <Route exact path="/index" element={<Index />} />
          <Route exact path="/auth-recoverpw" element={<RecoverPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/auth-login" element={<Login />} />
          <Route exact path="/createPR" element={<CreatePR />} />
          <Route exact path="/editPR" element={<EditPR />} />
          <Route exact path="/list-pr" element={<Table />} />
          <Route exact path="/pr/:pr_ref_no" element={<PurchaseDetails />} />
          <Route
            exact
            path="/admin/user-management"
            element={<UserManagement />}
          />
          <Route
            exact
            path="/admin/project-management"
            element={<ProjectManagement />}
          />
        </Routes>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
