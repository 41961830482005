import React, { useContext, useEffect, useState } from "react";
import Footer from "../Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import LeftSideBar from "../LeftSideBar";
import Navbar from "../Navbar";
import api from "../../helpers/api";
import { AuthContext } from "../../helpers/authContext";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import SpinnerUI from "../layouts/SpinnerUI";
import { Button } from "react-bootstrap";
import FormModal from "./UserModal";
import Swal from "sweetalert2";
import API from "../../helpers/baseApi";
import { showErrorAlert } from "../ui/SweetAlert";

export default function UserManagement() {
  const [loading, setLoading] = useState(true);
  const { accessToken, currentUser, theme } = useContext(AuthContext);
  const [allUsers, setAllUsers] = useState([]);
  const [action, setAction] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    fullname: "",
    position_id: "",
    role_id: "",
    status_id: "",
    job_role: "",
    company: "",
    on_site: "",
  });

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken, navigate]);

  // get all users + details
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getAllUsers(accessToken);
        setAllUsers(response.data);
        setLoading(false);
      } catch (error) {       
        setLoading(false);
      }
    };
    fetchData();
  }, [accessToken]);

  // table columns
  const columns = [
    {
      name: "#",
      selector: (row) => row.index,
      sortable: true,
      cell: (row, index) => <div>{index + 1}</div>,
      width: '55px',
    },
    {
      name: "Name",
      selector: (row) => row.user_fullname,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_fullname}</div>
    },
    {
      name: "Email",
      selector: (row) => row.user_email,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_email}</div>
    },
    {
      name: "Password",
      selector: (row) => row.user_password,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_password}</div>
    },
    {
      name: "Position",
      selector: (row) => row.position_name,
      sortable: true,
      cell: row => <div className="word-wrap">{row.position_name}</div>
    },
    {
      name: "Job Role",
      selector: (row) => row.user_job_role,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_job_role}</div>
    },
    {
      name: "Company",
      selector: (row) => row.user_company,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_company}</div>
    },
    {
      name: "On-site",
      selector: (row) => row.user_on_site,
      sortable: true,
      cell: row => <div className="word-wrap">{row.user_on_site ? row.user_on_site : "N/A" }</div>
    },
    {
      name: "Role",
      selector: (row) => row.role_name,
      sortable: true,
      width: "80px",
    },
    {
      name: "Status",
      selector: (row) => row.account_status_name,
      sortable: true,
      width: "85px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="">
          <Button
            style={{ marginRight: "10px" }}
            variant="warning"
            onClick={() => handleEditUser(row)}
          >
            <i className="mdi mdi-pencil-outline"></i>
          </Button>
          <Button variant="danger" onClick={() => handleDeleteUser(row)}>
            <i className="mdi mdi-trash-can-outline"></i>
          </Button>
        </div>
      ),
    },
  ];

  // handle edit user
  const handleEditUser = (row) => {
    setAction("edit");
    setShowModal(true);
    setInitialValues({
      user_id: row.user_id,
      email: row.user_email,
      password: row.user_password,
      fullname: row.user_fullname,
      position_id: row.user_position_id,
      role_id: row.user_role_id,
      status_id: row.user_account_status_id,
      job_role: row.user_job_role,
      company: row.user_company,
      on_site: row.user_on_site,
    });
  };

  // handle delete
  const handleDeleteUser = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, delete it!",
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        setLoading(true);
        const user_id = row.user_id;
        const formData = new FormData();
        formData.append("user_id", currentUser.user_id);

        try {
          await API.get("/api/csrf-cookie").then(async () => {
            const response = await API.put(
              `/api/admin/deleteUser/${user_id}`, formData,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Header": "value",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            if (response) {
              setLoading(false);
              Swal.fire({
                title: "Deleted!",
                text: response.data.message,
                icon: "success",
              });
              navigate("/");
            }
          });
        } catch (error) {   
          setLoading(false);    
          showErrorAlert(
            "Oops...",
            "Something went wrong! Please try again",
            "error"
          );
        }
      }
    });
  };

  // handle submit
  const handleSubmit = async (values) => {
    Swal.fire({
      title: "Are you sure to submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, submit it!",
    }).then(async (willSubmit) => {
      if (willSubmit.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("user_id", currentUser.user_id);
        formData.append("email", values.email);
        formData.append("password", values.password);
        formData.append("fullname", values.fullname);
        formData.append("position_id", values.position_id);
        formData.append("job_role", values.job_role);
        formData.append("company", values.company);
        formData.append("on_site", values.on_site);
        formData.append("role_id", values.role_id);
        formData.append("status_id", values.status_id);

        if (action === "add") {
          try {
            await API.get("/api/csrf-cookie").then(async () => {                
              const response = await API.post("/api/admin/createUser", formData, {
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Header": "value",
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              if (response) {
                setLoading(false);
                Swal.fire({
                  title: "Submitted!",
                  text: response.data.message,
                  icon: "success",
                });
                navigate("/");
              }
            });
          } catch (error) {   
            setLoading(false);
            showErrorAlert(
              "Oops...",
              "Something went wrong! Please try again",
              "error"
            );
          }
        } else if (action === "edit") {         
          const user_id = values.user_id;
          try {
            await API.get("/api/csrf-cookie").then(async () => {
              const response = await API.put(
                `/api/admin/editUser/${user_id}`,
                formData,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Header": "value",
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              if (response) {               
                setLoading(false);
                Swal.fire({
                  title: "Submitted!",
                  text: response.data.message,
                  icon: "success",
                });
                navigate("/");
              }
            });
          } catch (error) {    
            setLoading(false);    
            showErrorAlert(
              "Oops...",
              "Something went wrong! Please try again",
              "error"
            );
          }
        }
      }
    });
  };

  // display add user modal
  const handleAddUserModal = () => {
    setAction("add");
    setShowModal(true);
    setInitialValues({
      email: "",
      password: "",
      fullname: "",
      position_id: "",
      role_id: "",
      status_id: "",
    });
  };

  // close modal
  const handleCloseModal = () => {
    setLoading(false);
    setShowModal(false);
    setAction("");
  };

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <LeftSideBar />
        <div className="main-content">
          <div className="page-content">
            <div className="page-title-box">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="page-title">
                      <h4>User Management</h4>
                      <Breadcrumbs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="page-content-wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          {loading ? (
                            <SpinnerUI />
                          ) : (
                            <div className="table-users">                    
                              <DataTable
                                columns={columns}
                                data={allUsers}
                                theme={theme === 'dark' ? 'custom-dark' : 'light'}
                                pagination
                                title="List of Users"
                                fixedHeader="true"
                                fixedHeaderScrollHeight="300px"
                                highlightOnHover
                                defaultSortAsc={true}
                                actions={
                                  <Button
                                    variant="primary"
                                    onClick={() => handleAddUserModal()}
                                  >
                                    Add New User
                                  </Button>
                                }
                              />
                            </div>                            
                          )}
                          <FormModal
                            showModal={showModal}
                            closeModal={handleCloseModal}
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            token={accessToken}
                            action={action}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>      
    </>
  );
}
