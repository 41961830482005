export const getConfig = (token) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,
            // "Accept" : "application/json",
            "Custom-Header": "value",
        }
    }
    return config;
}