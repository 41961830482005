import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import LeftSideBar from "../LeftSideBar";
import Breadcrumbs from "../layouts/Breadcrumbs";
import FormPR from "./FormPR";
import Footer from "../Footer";
import { AuthContext } from "../../helpers/authContext";
import Swal from "sweetalert2";
import API from "../../helpers/baseApi";
import { showErrorAlert } from "../ui/SweetAlert";
import SpinnerUI from "../layouts/SpinnerUI";
import AddSignModal from "../ui/Modal/AddSignModal";

export default function EditPR() {
  const [loading, setLoading] = useState(false);
  const [uploadedSignature, setUploadedSignature] = useState("");
  const [showAddSignatureModal, setShowAddSignatureModal] = useState(false);
  const [values, setValues] = useState([]);
  const formMode = "editing";

  const location = useLocation();
  const purchase = location.state?.purchase;
  const category = location.state?.category;
  const itemAndSupplier = location.state?.itemAndSupplier;
  const project = location.state?.project;
  const payment_term = location.state?.payment_term;
  const quotations = location.state?.quotations;
  const requestor = location.state?.requestor;
  const log_ticket = location.state?.log_ticket;
  const other_files = location.state?.other_files;
  const editType = location.state?.editType;

  const { accessToken, currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const purchase_id = purchase.purchase_id;

  // check for R - repair request
  const pr_ref_no = purchase.purchase_pr_ref_no;
  const split_pr_ref_no = pr_ref_no?.split("-");
  let hasR = false;

  if (split_pr_ref_no > 2) {
    const result = split_pr_ref_no.slice(2).join("-");
    hasR = result.includes("R");
  }

  const initialValues = {
    category_id: category?.category_id,
    requestor_id: requestor.user_id,
    project_id: project?.project_id,
    edd: purchase?.purchase_edd,
    payment_term_id: payment_term?.payment_term_id,
    reason: purchase?.purchase_reason,
    total_all: purchase?.purchase_total_all,
    radio_hospital_repair: hasR ? "yes" : "no",
    radio_log_ticket: log_ticket ? "yes" : "no",
    log_ticket_number: log_ticket ? log_ticket.log_ticket_number : null,
    log_ticket_file: log_ticket ? [
      {
        id: log_ticket.log_ticket_id,
        name: log_ticket.log_ticket_file_name,
        isExisting: true,
      },
    ] : null,
    quotation: quotations?.map((file) => ({
      id: file.quotation_id,
      name: file.quotation_file_name,
      isExisting: true,
    })),
    removedQuotationIds: [],
    removedOtherFileIds: [],
    removedLogTicketFileId: "",
    removeItemId: [],
    other_file: other_files?.map((file) => ({
      id: file.other_file_id,
      name: file.other_file_name,
      isExisting: true,
    })),
    suppliers: Object.entries(itemAndSupplier).map(([key, supplier]) => ({
      supplier_id: supplier.supplier_id,
      supplier_name: supplier.supplier_name,
      type: supplier.supplier_type,
      contact_person: supplier.contact_person,
      phone_number: supplier.phone_number,
      email: supplier.email,
      address: supplier.address,
      items: supplier?.items?.map((item) => ({
        item_id: item.item_id,
        item_code: item.item_code,
        description: item.description,
        quantity: item.quantity,
        claim: item.claim,
        unit_price: item.unit_price,
        total_price: item.total_price,
        isExisting: true,
      })),
    })),
  };

  const handleCloseModal = (image_url) => {
    setShowAddSignatureModal(false);
    submitFormData(values, image_url);
  };

  const handleShowAddSignatureModal = () => {
    setShowAddSignatureModal(true);
  };

  const handleUploadedSignature = (image_url) => {
    setUploadedSignature(image_url);
  };

  const handleSubmit = async (values) => {
    Swal.fire({
      title: "Are you sure to submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, submit it!",
    }).then(async (willSubmit) => {
      if (willSubmit.isConfirmed) {
        if (editType === "draft") {
          handleShowAddSignatureModal();
        }
        setValues(values);
        setLoading(true);
      }
    });
  };

  const submitFormData = async (values, image_url) => {
    setLoading(true);
    const formData = new FormData();

    // normal input -> formData (no array / nested)
    formData.append("user_id", currentUser.user_id);
    formData.append("category_id", values.category_id);
    formData.append("reason", values.reason);
    formData.append("edd", values.edd);
    formData.append("project_id", values.project_id);
    formData.append("payment_term_id", values.payment_term_id);
    formData.append("total_all", values.total_all);
    formData.append("radio_hospital_repair", values.radio_hospital_repair);
    formData.append("signature_url", image_url);
    formData.append("form_type", values.form_type);
    formData.append("edit_type", editType);

    // suppliers -> formData
    if (values.suppliers) {
      // append suppliers
      values.suppliers.forEach((supplier, supplierIndex) => {
        for (let supplierKey in supplier) {
          formData.append(
            `supplier[${supplierIndex}][${supplierKey}]`,
            supplier[supplierKey]
          );
        }
        // append items
        if (supplier.items) {
          supplier.items.forEach((item, itemIndex) => {
            for (let itemKey in item) {
              formData.append(
                `supplier[${supplierIndex}][item][${itemIndex}][${itemKey}]`,
                item[itemKey]
              );
            }
          });
        }
      });
    }

    // quotation -> fileData
    values.quotation.forEach((file, index) => {
      if (file.isExisting) {
        formData.append(`quotationExisting[${index}][id]`, file.id);
      } else {
        formData.append(`quotationNew[${index}]`, file.file);
      }
    });

    // removed quotation id
    values.removedQuotationIds.forEach((id, index) => {
      formData.append(`removedQuotationIds[${index}]`, id);
    });

    // other files -> fileData
    values.other_file.forEach((file, index) => {
      if (file.isExisting) {
        formData.append(`otherFileExisting[${index}][id]`, file.id);
      } else {
        formData.append(`otherFileNew[${index}]`, file.file);
      }
    });

    // removed other file id
    values.removedOtherFileIds.forEach((id, index) => {
      formData.append(`removedOtherFileIds[${index}]`, id);
    });

    // log ticket -> fileData
    if (values.log_ticket_file && values.log_ticket_file.length > 0) {
      formData.append("log_ticket_number", values.log_ticket_number);
      formData.append(`logTicketFileNew`, values.log_ticket_file);
    }

    // removed log ticket id
    if (values.removedLogTicketFileId) {
      formData.append("removedLogTicketFileId", values.removedLogTicketFileId);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    // send formData -> API (non file type input)
    // try {
    //   const response = await API.post(`/api/editPR/${purchase_id}`, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   });
    //   setLoading(true);
    //   if (response) {
    //     setLoading(false);
    //     Swal.fire({
    //       title: "Submitted!",
    //       text: response.data.message,
    //       icon: "success",
    //     });
    //     navigate("/list-pr");
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   showErrorAlert(
    //     "Oops...",
    //     "Something went wrong! Please try again",
    //     "error"
    //   );
    // }
  };

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <LeftSideBar />
        <div className="main-content">
          <div className="page-content">
            <div className="page-title-box">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="page-title">
                      <h4>Edit Purchase Request</h4>
                      <Breadcrumbs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="page-content-wrapper">
                <div className="row">
                  {loading ? (
                    <div className="SpinnerUI-overlay">
                      <SpinnerUI />
                    </div>
                  ) : (
                    <div className="col-lg-12">
                      <FormPR
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        editType={editType}
                        submitFormData={submitFormData}
                        formMode={formMode}
                      />
                    </div>
                  )}
                  <AddSignModal
                    showModal={showAddSignatureModal}
                    closeModal={handleCloseModal}
                    uploadedSignature={handleUploadedSignature}
                    callingFrom="edit_form"
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
