import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import api from "../helpers/api";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import Navbar from "./Navbar";
import LeftSideBar from "./LeftSideBar";
import Footer from "./Footer";
import { AuthContext } from "../helpers/authContext";
import Breadcrumbs from "./layouts/Breadcrumbs";
import Swal from "sweetalert2";
import { showErrorAlert } from "./ui/SweetAlert";
import API from "../helpers/baseApi";
import SpinnerUI from "./layouts/SpinnerUI";
import AddSignModal from "./ui/Modal/AddSignModal";
import ViewUploadedFileModal from "./ui/Modal/ViewUploadedFileModal";
import RejectPRModal from "./ui/Modal/RejectPRModal";
import PrintPRModal from "./ui/Modal/PrintPRModal";

export default function PurchaseDetails() {
  const location = useLocation();
  const user_id = location.state?.user_id;
  const { pr_ref_no } = useParams();
  const [purchase, setPurchase] = useState([]);
  const [project, setProject] = useState([]);
  const [status, setStatus] = useState([]);
  const [paymentTerm, setPaymentTerm] = useState([]);
  const [itemAndSupplier, setItemAndSupplier] = useState([]);
  const [requestor, setRequestor] = useState([]);
  const [signature, setSignature] = useState([]);
  const [note, setNote] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [logTicket, setLogTicket] = useState([]);
  const [category, setCategory] = useState([]);
  const [showUploadedFileModal, setShowUploadedFileModal] = useState(false);
  const [showAddSignatureModal, setShowAddSignatureModal] = useState(false);
  const [showRejectPRModal, setShowRejectPRModal] = useState(false);
  const [showPrintPRModal, setShowPrintPRModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const { accessToken, currentUser, theme } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const formData = new FormData();

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken]);

  // get specific pr + details
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.getSpecificPR(pr_ref_no, accessToken);
        setPurchase(response.data.purchase[0]);
        setCategory(response.data.category[0]);
        setItemAndSupplier(response.data.items_and_suppliers);
        setProject(response.data.project[0]);
        setPaymentTerm(response.data.payment_term[0]);
        setQuotations(response.data.quotations);
        setLogTicket(response.data.log_ticket[0]);
        setRequestor(response.data.requestor[0]);
        setSignature(response.data.signatures);
        setNote(response.data.notes);
        setStatus(response.data.status[0]);
        setOtherFiles(response.data.other_files);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // flatten data (remove nested structure - for react-data-table-component)
  const flattenItemsData = (data) => {
    const flatData = [];
    data.forEach(supplier => {
      supplier.items.forEach(item => {
        flatData.push({
          supplier_id: supplier.supplier_id,
          supplier_name: supplier.supplier_name,
          contact_person: supplier.contact_person,
          email: supplier.email,
          supplier_type: supplier.supplier_type,
          phone_number: supplier.phone_number,
          address: supplier.address,
          item_code: item.item_code,
          description: item.description,
          quantity: item.quantity,
          unit_price: item.unit_price,
          total_price: item.total_price,
          claim: item.claim,
        })
      })
    })
    return flatData;
  }

  const flatData = flattenItemsData(itemAndSupplier);

  const columns = [
    {
      name: "#",
      selector: (row) => row.index,
      sortable: true,
      cell: (row, index) => <div>{index + 1}</div>,
      width: "55px",
    },
    {
      name: "Item Code",      
      selector: (row) => row.claim,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.item_code}</div>,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.description}</div>,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Unit Price (RM)",
      selector: (row) => row.unit_price.toFixed(2),
      sortable: true,
    },
    {
      name: "Total Price (RM)",
      selector: (row) => row.total_price.toFixed(2),
      sortable: true,
    },
    {
      name: "Claim",
      selector: (row) => row.claim,
      sortable: true,
      cell: (row) => <div className="word-wrap">{row.claim}</div>,
    },
  ];

  // component display supplier details in expandable rows
  const SupplierDetailsComponent = ({ data }) => (
    <div className="card-body" id="supplier-details-component">
      <div className="row">
        <i className="mdi mdi-store-outline"></i>
        <span style={{ textDecorationLine: "underline", fontWeight: "bold" }}>
          {data.supplier_name}
        </span>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <i className="mdi mdi-account-outline"></i>{" "}
          {data.contact_person}
        </div>
        <div className="col-sm-6">
          <i className="mdi mdi-email-outline"></i> {data.email}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <i className="mdi mdi-cart-variant"></i> {data.supplier_type}
        </div>
        <div className="col-sm-6">
          <i className="mdi mdi-phone-outline"></i> {data.phone_number}
        </div>
      </div>
      <div className="row">
        <div className="col-sm">
          <i className="mdi mdi-map-marker-outline"></i> {data.address}
        </div>
      </div>
    </div>
  );

  // sub header
  const SubHeader = ({ data }) => (
    <p style={{ color: theme === "light" ? "#000" : "#fff" }}>
      Overall Total Price: RM{data?.purchase_total_all?.toFixed(2)}
    </p>
  );

  // upload signature + additional notes to API
  const handleUploadedSignature = async (signature_url, additional_notes) => {
    setLoading(true);
    const purchase_id = purchase.purchase_id;

    formData.append("signature_url", signature_url); //base64 string of image
    formData.append("additional_notes", additional_notes);
    formData.append("approval_type", "approved");
    formData.append("user_id", user_id);

    try {
      await API.get("/api/csrf-cookie").then(async () => {
        const response = await API.post(
          `/api/approvalProcess/${purchase_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Custom-Header": "value",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          setLoading(false);
          Swal.fire({
            title: "Successful!",
            text: response.data.message,
            icon: "success",
          });
          navigate("/list-pr");
        }
      });
    } catch (error) {
      setLoading(false);
      showErrorAlert(
        "Oops...",
        "Something went wrong! Please try again",
        "error"
      );
    }
  };

  // upload additional notes of rejected PR
  const handleUploadedRejectedPRAdditionalNotes = async (reason_reject) => {
    setLoading(true);
    const purchase_id = purchase.purchase_id;

    formData.append("reason_reject", reason_reject);
    formData.append("approval_type", "rejected");
    formData.append("user_id", user_id);

    try {
      await API.get("/api/csrf-cookie").then(async () => {
        const response = await API.post(
          `/api/approvalProcess/${purchase_id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Custom-Header": "value",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response) {
          setLoading(false);
          Swal.fire({
            title: "Successful!",
            text: response.data.message,
            icon: "success",
          });
          navigate("/list-pr");
        }
      });
    } catch (error) {
      setLoading(false);
      showErrorAlert(
        "Oops...",
        "Something went wrong! Please try again",
        "error"
      );
    }
  };

  const handleCloseModal = () => {
    setShowUploadedFileModal(false);
    setShowAddSignatureModal(false);
    setShowRejectPRModal(false);
    setShowPrintPRModal(false);
  };
  const handleShowUploadedFileModal = (file_url) => {
    setShowUploadedFileModal(true);
    setSelectedFile(file_url);
  };

  const handleShowAddSignatureModal = (file_url) => {
    setShowAddSignatureModal(true);
    setSelectedFile(file_url);
  };

  const handleShowPrintPRModal = () => {
    setShowPrintPRModal(true);
  };

  const handleShowRejectPRModal = () => {
    Swal.fire({
      title: "Are you sure to reject this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, reject it!",
    }).then(async (willReject) => {
      if (willReject.isConfirmed) {
        // display modal
        setShowRejectPRModal(true);
      }
    });
  };

  // component display quotations
  const QuotationFile = ({}) => {
    return (
      <>
        {quotations.map((quotation, index) => (
          <li
            className="file-list"
            key={index}
            onClick={() =>
              handleShowUploadedFileModal(quotation.quotation_file_url)
            }
          >
            <i className="mdi mdi-file-document"></i>
            {quotation.quotation_file_name}
          </li>
        ))}
      </>
    );
  };

  // component display other files
  const OtherFiles = ({}) => {
    return (
      <>
        {otherFiles.length > 0 ? (
          otherFiles.map((otherFile, index) => (
            <li
              className="file-list"
              key={index}
              onClick={() =>
                handleShowUploadedFileModal(otherFile.other_file_url)
              }
            >
              <i className="mdi mdi-file-document"></i>
              {otherFile.other_file_name}
            </li>
          ))
        ) : (
          <p>N/A</p>
        )}
      </>
    );
  };

  // component display log tickets
  const LogTicketFile = ({}) => {
    return (
      <>
        {logTicket ? (
          <li
            className="file-list"
            onClick={() =>
              handleShowUploadedFileModal(logTicket.log_ticket_file_url)
            }
          >
            <i className="mdi mdi-file-document"></i>
            {logTicket.log_ticket_file_name}
          </li>
        ) : (
          <p>N/A</p>
        )}
      </>
    );
  };

  // handle click more actions - delete
  const handleDelete = async () => {
    Swal.fire({
      title: "Are you sure to delete this?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, delete it!",
    }).then(async (willDelete) => {
      if (willDelete.isConfirmed) {
        const user_id = currentUser.user_id;
        const formData = new FormData();
        formData.append("user_id", user_id);

        try {
          await API.get("/api/csrf-cookie").then(async () => {
            const response = await API.put(
              `/api/deletePR/${pr_ref_no}`,
              formData,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Custom-Header": "value",
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );
            if (response) {
              Swal.fire({
                title: "Deleted!",
                text: response.data.message,
                icon: "success",
              });
              navigate("/list-pr");
            }
          });
        } catch (error) {
          showErrorAlert(
            "Oops...",
            "Something went wrong! Please try again",
            "error"
          );
        }
      }
    });
  };

  // handle click more actions - edit
  const handleEdit = async () => {
    let editType = "";
    if (status.status_id === 4) {
      editType = "rejected";
    } else {
      editType = "draft";
    }

    Swal.fire({
      title: "Are you sure to edit this?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, edit it!",
    }).then(async (willEdit) => {
      if (willEdit.isConfirmed) {
        navigate("/editPR", {
          state: {
            purchase: purchase,
            category: category,
            itemAndSupplier: itemAndSupplier,
            project: project,
            payment_term: paymentTerm,
            quotations: quotations,
            log_ticket: logTicket,
            requestor: requestor,
            status: status,
            other_files: otherFiles,
            editType: editType,
          },
        });
      }
    });
  };

  // convert UTC -> local date time
  const convertDateTime = (unformatted_date) => {
    const purchaseDate = unformatted_date;
    const date = new Date(purchaseDate);
    const formattedDate = date.toLocaleString();

    return formattedDate;
  };

  // remove underscore - pr status
  const removeUnderscore = (str) => {
    return str?.replace(/_/g, " ");
  };

  // display multiple line reason
  const displayMultipleLineReason = (str) => {
    return str?.split("\n").map((line, index) => <div key={index}>{line}</div>);
  };

  // signature component
  const SignatureComponent = () =>
    signature.map((signature, index) => {
      return (
        <div className="col" key={index}>
          {signature.position_name}
          <br />
          <img src={signature.signature_file_url} className="mt-3 mb-5" />
          <br />
          {signature.user_fullname}
          <br />
          {signature.user_job_role}
          <br />
          {signature.user_company}
          <br />
          {signature.user_on_site === "N/A" ? null : signature.user_on_site}
          <br />
          <br />
          Signed At: {convertDateTime(signature.signature_created_at)}
          <br />
        </div>
      );
    });

  return (
    <>
      <div id="layout-wrapper">
        <Navbar />
        <LeftSideBar />
        <div className="main-content">
          <div className="page-content">
            <div className="page-title-box">
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-sm-6">
                    <div className="page-title">
                      <h4>Purchase Request Details</h4>
                      <Breadcrumbs />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="page-content-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="card">
                      {loading ? (
                        <SpinnerUI />
                      ) : (
                        <>
                          <span
                            className="label-status"
                            style={{
                              backgroundColor:
                                status.status_color_code_background,
                              color: status.status_color_code_text,
                            }}
                          >
                            {removeUnderscore(status.status_name)}
                          </span>
                          <div className="card-body custom-label">
                            <div className="row mb-5">
                              <div id="section-purchase" className="col-sm">
                                <span style={{ fontWeight: "bold" }}>
                                  PR Ref. No.: {purchase.purchase_pr_ref_no}
                                </span>
                                <br />
                                Created At:{" "}
                                {convertDateTime(purchase.purchase_created_at)}
                                <br />
                                Updated At:{" "}
                                {convertDateTime(purchase.purchase_updated_at)}
                                <br />
                                Project: {project.project_name}
                                <br />
                                EDD:{" "}
                                {purchase.purchase_edd === '' || purchase.purchase_edd === '0000-00-00' ? (<span>N/A</span>) : (purchase.purchase_edd)}
                                <br />
                                Payment Term: {paymentTerm.payment_term_name}
                                <br />
                                Requestor: {requestor.user_fullname}
                                <br />
                              </div>

                              {/* more actions dropdown (only for requestor) */}
                              {user_id === purchase?.purchase_requestor_id &&
                              status?.status_id !== 7 ? (
                                //if it is the requestor
                                <div className="col-sm d-flex align-items-end flex-column">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      More actions{" "}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      id="more-actions"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        id="print"
                                        onClick={handleShowPrintPRModal}
                                      >
                                        <i className="mdi mdi-printer"></i>{" "}
                                        Print
                                      </a>
                                      {status?.status_id === 4 ? (
                                        <a
                                          className="dropdown-item"
                                          id="edit"
                                          onClick={handleEdit}
                                        >
                                          <i className="mdi mdi-square-edit-outline"></i>{" "}
                                          Edit
                                        </a>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              ) : user_id === purchase?.purchase_requestor_id &&
                                status?.status_id === 7 ? (
                                <div className="col-sm d-flex align-items-end flex-column">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton"
                                      data-bs-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      More actions{" "}
                                    </button>
                                    <div
                                      className="dropdown-menu"
                                      id="more-actions"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        className="dropdown-item"
                                        id="edit"
                                        onClick={handleEdit}
                                      >
                                        <i className="mdi mdi-square-edit-outline"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        id="delete"
                                        onClick={handleDelete}
                                      >
                                        <i className="mdi mdi-delete"></i>{" "}
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : //if not requestor + already approve/reject
                              (note?.some(
                                  (nota) => nota.note_user_id === user_id
                                ) ||
                                  signature?.some(
                                    (sign) => sign.signature_user_id === user_id
                                  )) &&
                                purchase.purchase_status_id != 6 ? (
                                <div
                                  className="col-sm"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <Button
                                    variant="warning"
                                    style={{ width: "100px" }}
                                    onClick={handleShowPrintPRModal}
                                  >
                                    <i className="mdi mdi-printer"></i> Print
                                  </Button>
                                </div>
                              ) : (
                                // not requestor + haven't approve/reject
                                <>
                                  <div
                                    className="col-sm"
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "flex-start",
                                    }}
                                  >
                                    <Button
                                      variant="success"
                                      style={{
                                        marginRight: "10px",
                                        width: "100px",
                                      }}
                                      onClick={handleShowAddSignatureModal}
                                    >
                                      <i className="mdi mdi-check"></i> Approve
                                    </Button>
                                    <Button
                                      variant="danger"
                                      style={{
                                        width: "100px",
                                        marginRight: "10px",
                                      }}
                                      onClick={handleShowRejectPRModal}
                                    >
                                      <i className="mdi mdi-close"></i> Reject
                                    </Button>
                                    <Button
                                      variant="warning"
                                      style={{ width: "100px" }}
                                      onClick={handleShowPrintPRModal}
                                    >
                                      <i className="mdi mdi-printer"></i> Print
                                    </Button>
                                  </div>

                                  <AddSignModal
                                    showModal={showAddSignatureModal}
                                    closeModal={handleCloseModal}
                                    uploadedSignature={handleUploadedSignature}
                                  />

                                  <>
                                    <RejectPRModal
                                      showModal={showRejectPRModal}
                                      closeModal={handleCloseModal}
                                      uploadedRejectedPRAdditionalNotes={
                                        handleUploadedRejectedPRAdditionalNotes
                                      }
                                    />
                                    <br />
                                    <br />
                                  </>
                                </>
                              )}
                            </div>
                            <div className="row">
                              <div id="section-reason" className="col-sm mb-5">
                                <h5>Reason</h5>
                                <p>
                                  {displayMultipleLineReason(
                                    purchase.purchase_reason
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div
                                id="section-quotations"
                                className="col-sm mb-5"
                              >
                                <h5>Quotations</h5>
                                <QuotationFile />
                              </div>

                              <div
                                id="section-log-tickets"
                                className="col-sm mb-5"
                              >
                                <h5>Log Ticket</h5>
                                <LogTicketFile />
                              </div>

                              <div
                                id="section-other-files"
                                className="col-sm mb-5"
                              >
                                <h5>Other Files</h5>
                                <OtherFiles />
                              </div>
                            </div>

                            <div id="section-item-supplier" className="mb-5">
                              <h5>Items & Suppliers</h5>
                              <DataTable
                                columns={columns}
                                data={flatData}
                                pagination
                                fixedHeader="true"
                                fixedHeaderScrollHeight="300px"
                                highlightOnHover
                                defaultSortAsc={true}
                                subHeader
                                subHeaderComponent={
                                  <SubHeader data={purchase} />
                                }
                                expandableRows
                                expandableRowsComponent={
                                  SupplierDetailsComponent
                                }
                                theme={
                                  theme === "dark" ? "custom-dark" : "light"
                                }
                              />
                            </div>

                            <div id="section-signatures" className="mb-5">
                              <h5>Uploaded Signatures</h5>
                              <div className="mt-3">
                                {signature.length === 0 ? (
                                  <p>No signature added yet</p>
                                ) : (
                                  <div className="row">
                                    {/* requestor -> PM -> SM -> CEO */}
                                    {SignatureComponent()}
                                    {note.map((note) =>
                                      note.note_type === 1 ? (
                                        <p key={note.note_id}>
                                          Additional Notes: {note.note_words}{" "}
                                        </p>
                                      ) : null
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>

                            {purchase ? (
                              <div
                                id="section-rejection-reason"
                                className="mb-5"
                              >
                                <h5>Reason of Rejecting</h5>
                                {!note || note.length === 0 ? (
                                  <p>N/A</p>
                                ) : (
                                  note
                                    .filter((note) => note.note_type === 0)
                                    .map((note) => (
                                      <>
                                        <p>Reason: {note.note_words}</p>
                                        <p>Written By: {note.user_fullname}</p>
                                        <p>
                                          Written on:{" "}
                                          {convertDateTime(
                                            note.note_created_at
                                          )}
                                        </p>
                                      </>
                                    ))
                                )}
                              </div>
                            ) : (
                              <SpinnerUI />
                            )}

                            <ViewUploadedFileModal
                              showModal={showUploadedFileModal}
                              closeModal={handleCloseModal}
                              fileURL={selectedFile}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <PrintPRModal
              data={purchase}
              item={flatData}
              signature={signature}
              note={note}
              logTickets={logTicket}
              requestor={requestor}
              project={project}
              paymentTerm={paymentTerm}
              showModal={showPrintPRModal}
              closeModal={handleCloseModal}
            />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
