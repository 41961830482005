import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../helpers/authContext';
import api from '../../helpers/api';
import { Button } from 'react-bootstrap';
import { showErrorAlert } from '../ui/SweetAlert';
import Swal from 'sweetalert2';
import API from '../../helpers/baseApi';
import Navbar from '../Navbar';
import LeftSideBar from '../LeftSideBar';
import Breadcrumbs from '../layouts/Breadcrumbs';
import SpinnerUI from '../layouts/SpinnerUI';
import DataTable from 'react-data-table-component';
import Footer from '../Footer';
import ProjectModal from './ProjectModal';

export default function ProjectManagement() {
    const [loading, setLoading] = useState(true);
    const { accessToken, currentUser, theme } = useContext(AuthContext);
    const [allProjects, setAllProjects] = useState([]);
    const [action, setAction] = useState("");
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const [initialValues, setInitialValues] = useState({
      project_name: "",
      program_manager: "",
      start_date: "",
      end_date: "",
      project_description: "",
    });    
  
    //check if has access token
    useEffect(() => {
      if (!accessToken) navigate("/auth-login");
    }, [accessToken, navigate]);
  
    // get all users + details
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await api.getAllProjects(accessToken);
          setAllProjects(response.data);
          setLoading(false);
        } catch (error) {     
          setLoading(false);
        }
      };
      fetchData();
    }, [accessToken]);
  
    // convert YYYY-MM-DD to DD-MM-YYYY
    const formatDate = (unformatted_date) => {
      const old_date = unformatted_date;
      const date = new Date(old_date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
  
      // concat
      const formatted_date = `${day}-${month}-${year}`;
      return formatted_date;
    };
  
    // table columns
    const columns = [
      {
        name: "#",
        selector: (row) => row.index,
        sortable: true,
        cell: (row, index) => <div>{index + 1}</div>,
        width: '55px',
      },
      {
        name: "Project",
        selector: (row) => row.project_name,
        sortable: true,
        cell: row => <div className="word-wrap">{row.project_name}</div>
      },
      {
        name: "Program Manager",
        selector: (row) => row.user_fullname,
        sortable: true,
        cell: row => <div className="word-wrap">{row.user_fullname}</div>
      },
      {
        name: "Start Date",
        selector: (row) => row.project_start_date,
        sortable: true,
        cell: row => <div className="word-wrap">{formatDate(row.project_start_date)}</div>
      },
      {
        name: "End Date",
        selector: (row) => row.project_end_date,
        sortable: true,
        cell: row => <div className="word-wrap">{formatDate(row.project_end_date)}</div>
      },
      {
        name: "Description",
        selector: (row) => row.project_description,
        sortable: true,
        cell: row => <div className="word-wrap">{row.project_description}</div>
      },      
      {
        name: "Actions",
        cell: (row) => (
          <div className="">
            <Button
              style={{ marginRight: "10px" }}
              variant="warning"
              onClick={() => handleEditProject(row)}
            >
              <i className="mdi mdi-pencil-outline"></i>
            </Button>
            <Button variant="danger" onClick={() => handleDeleteProject(row)}>
              <i className="mdi mdi-trash-can-outline"></i>
            </Button>
          </div>
        ),
      },
    ];
  
    // handle edit user
    const handleEditProject = (row) => {
      setAction("edit");
      setShowModal(true);
      setInitialValues({
        project_id: row.project_id,
        project_name: row.project_name,
        program_manager: row.user_id,
        start_date: row.project_start_date,
        end_date: row.project_end_date,
        project_description: row.project_description,
      });
    };
  
    // handle delete
    const handleDeleteProject = (row) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#564ab1",
        cancelButtonColor: "#f14e4e",
        confirmButtonText: "Yes, delete it!",
      }).then(async (willDelete) => {
        if (willDelete.isConfirmed) {
          setLoading(true);
          const project_id = row.project_id;
          const formData = new FormData();
          formData.append("user_id", currentUser.user_id);

          try {
            await API.get("/api/csrf-cookie").then(async () => {
              const response = await API.put(
                `/api/admin/deleteProject/${project_id}`, formData,
                {
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Header": "value",
                    Authorization: `Bearer ${accessToken}`,
                  },
                }
              );
              if (response) {
                setLoading(false);
                Swal.fire({
                  title: "Deleted!",
                  text: response.data.message,
                  icon: "success",
                });
                navigate("/");
              }
            });
          } catch (error) {     
            setLoading(false);
            showErrorAlert(
              "Oops...",
              "Something went wrong! Please try again",
              "error"
            );
          }
        }
      });
    };
  
    // handle submit
    const handleSubmit = async (values) => {
      Swal.fire({
        title: "Are you sure to submit?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#564ab1",
        cancelButtonColor: "#f14e4e",
        confirmButtonText: "Yes, submit it!",
      }).then(async (willSubmit) => {
        if (willSubmit.isConfirmed) {
          setLoading(true);

          const formData = new FormData();
          formData.append("user_id", currentUser.user_id);
          formData.append("project_name", values.project_name);
          formData.append("program_manager", values.program_manager);
          formData.append("start_date", values.start_date);
          formData.append("end_date", values.end_date);
          formData.append("description", values.project_description);
  
          if (action === "add") {
            try {
              await API.get("/api/csrf-cookie").then(async () => {
                for (var pair of formData.entries()) {
                  console.log(pair[0]+ ', ' + pair[1]); 
                }   
                const response = await API.post("/api/admin/createProject", formData, {
                  headers: {
                    "Content-Type": "application/json",
                    "Custom-Header": "value",
                    "Authorization": `Bearer ${accessToken}`,
                  },
                });
                if (response) {
                  setLoading(false);
                  Swal.fire({
                    title: "Submitted!",
                    text: response.data.message,
                    icon: "success",
                  });
                  navigate("/");
                }
              });
            } catch (error) {  
              setLoading(false);      
              showErrorAlert(
                "Oops...",
                "Something went wrong! Please try again",
                "error"
              );
            }
          } else if (action === "edit") {          
            const project_id = values.project_id;
            try {
              await API.get("/api/csrf-cookie").then(async () => {
                const response = await API.put(
                  `/api/admin/editProject/${project_id}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      "Custom-Header": "value",
                      "Authorization": `Bearer ${accessToken}`,
                    },
                  }
                );
                if (response) {
                  setLoading(false);
                  Swal.fire({
                    title: "Submitted!",
                    text: response.data.message,
                    icon: "success",
                  });
                  navigate("/");
                }
              });
            } catch (error) {   
              setLoading(false);     
              showErrorAlert(
                "Oops...",
                "Something went wrong! Please try again",
                "error"
              );
            }
          }
        }
      });
    };
  
    // display add user modal
    const handleAddProjectModal = () => {
      setAction("add");
      setShowModal(true);
      setInitialValues({
        project_name: "",
        program_manager: "",
        start_date: "",
        end_date: "",
        project_description: "",
      });
    };
  
    // close modal
    const handleCloseModal = () => {
      setShowModal(false);
      setAction("");
    };
  
    return (
      <>
        <div id="layout-wrapper">
          <Navbar />
          <LeftSideBar />
          <div className="main-content">
            <div className="page-content">
              <div className="page-title-box">
                <div className="container-fluid">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <div className="page-title">
                        <h4>Project Management</h4>
                        <Breadcrumbs />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="page-content-wrapper">
                  <div className="row">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            {loading ? (
                              <SpinnerUI />
                            ) : (
                              <div className="table-projects">  
                                <DataTable
                                  columns={columns}
                                  data={allProjects}
                                  theme={theme === 'dark' ? 'custom-dark' : 'light'}
                                  pagination
                                  title="List of Projects"
                                  fixedHeader="true"
                                  fixedHeaderScrollHeight="300px"
                                  highlightOnHover
                                  defaultSortAsc={true}
                                  actions={
                                    <Button
                                      variant="primary"
                                      onClick={() => handleAddProjectModal()}
                                    >
                                      Add New Project
                                    </Button>
                                  }
                                />
                              </div>
                            )}                         
                            <ProjectModal
                              showModal={showModal}
                              closeModal={handleCloseModal}
                              initialValues={initialValues}
                              onSubmit={handleSubmit}
                              token={accessToken}
                              action={action}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>        
      </>
    );
}
