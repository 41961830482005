import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { AuthContext } from "../../helpers/authContext";

export default function SpinnerUI() {
  const {theme} = useContext(AuthContext);
  return (
    <div className="d-flex justify-content-center">
      <Spinner animation='border' variant={theme === "dark" ? "light" : "dark"}/>
    </div>
  );
}
