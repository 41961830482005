import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../helpers/authContext";
import api from "../../../helpers/api";
import Chart from "react-apexcharts";

export default function RequestorGraph({ value }) {
  const { accessToken } = useContext(AuthContext);
  const [countPR, setCountPR] = useState([]);
  const [total, setTotal] = useState([]);
  const [category, setCategory] = useState([]);
  const navigate = useNavigate();

  //check if has access token
  useEffect(() => {
    if (!accessToken) navigate("/auth-login");
  }, [accessToken]);

  // get number of pr based on status
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.getPRStatusGraph(accessToken);
        setCountPR(response.data);
        const total = response.data.map((item) => item.total);
        const category = response.data.map((item) => item.status_name);
        setCategory(category);
        setTotal(total);
        value(response.data);
      } catch (error) {
        console.log("Error:", error);
      }
    };
    fetchData();
  }, []);

  const barChart = {
    options: {
      chart: {
        id: "basic-bar",
        height: 380,
        toolbar: { show: false },
        zoom: { enabled: true },
      },
      xaxis: {
        categories: category,
      },
    },
    plotOptions: {
      bar: { horizontal: false, columnWidth: "20%", endingShape: "rounded" },
    },
    dataLabels: { enabled: true },
    series: [
      {
        name: "Total",
        data: total,
      },
    ],
    colors: ["#525ce5", "#edf1f5"],
    legend: { show: false },
    fill: { opacity: 1 },
  };

  return (
    <Chart
      options={barChart.options}
      series={barChart.series}
      type="bar"
      width="500"
      height={barChart.options.chart.height}
    />
  );
}
