import React, { useEffect, useState } from 'react'

export default function Clock() {
    const [timer, setTimer] = useState(new Date());

    const tick = () => {
        setTimer(new Date)
    };

    useEffect(() => {
        let timerID = setInterval(() => {
            tick();
        }, 1000)

        return () => {
            clearInterval(timerID);
        }
    }, []);

    return (        
        <span>{timer.toLocaleString()}</span>        
    )
}
