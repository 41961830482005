import React, { useContext, useState } from "react";
import Monthly from "./Monthly";
import Yearly from "./Yearly";
import { AuthContext } from "../../../helpers/authContext";
import RequestorGraph from "./RequestorGraph";

export default function TotalPRStatus() {
  const [activeTab, setActiveTab] = useState("month");
  const [value, setValue] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleTotalPR = (total) => {
    setValue(total);
  };

  return (
    <div className="col-xl">
      <div className="card">
        <div className="card-body">
          <h4 className="header-title mb-4 float-sm-start">
            Total Purchase Request
          </h4>

          {/* Time period tab - month/year */}
          {currentUser.user_position_id !== 4 ? (
            <div className="float-sm-end">
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "month" ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => handleTabClick("month")}
                  >
                    Month
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "year" ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => handleTabClick("year")}
                  >
                    Year
                  </a>
                </li>
              </ul>
            </div>
          ) : null}

          <div className="clearfix"></div>

          {/* Stakced column chart */}
          <div className="row align-items-center">
            <div className="col-xl-9">
              <div style={{ position: "relative" }}>
                <div
                  id="stacked-column-chart"
                  className="apex-charts"
                  dir="ltr"
                >
                  {currentUser.user_position_id !== 4 ? (
                    <>
                      {activeTab === "month" && (
                        <Monthly value={handleTotalPR} />
                      )}
                      {activeTab === "year" && <Yearly value={handleTotalPR} />}
                    </>
                  ) : (
                    <RequestorGraph value={handleTotalPR} />
                  )}
                </div>
              </div>
            </div>

            {/* Summary of the chart */}
            <div className="col-xl-3">
              <div className="dash-info-widget mt-4 mt-lg-0 py-4 px-3">
                <div className="media dash-main-border pb-2">
                  <div className="avatar-sm mb-3 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-plus-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Pending_Program_Manager ? value?.Pending_Program_Manager : 0}
                    </h4>
                    <p className="text-muted">Pending Program Manager</p>
                  </div>
                </div>

                <div className="media mt-4 dash-main-border pb-2">
                  <div className="avatar-sm mb-3 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-clock-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Pending_CEO ? value?.Pending_CEO : 0}
                    </h4>
                    <p className="text-muted">Pending CEO</p>
                  </div>
                </div>

                <div className="media mt-4 dash-main-border pb-2">
                  <div className="avatar-sm mb-3 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-clock-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Pending_Senior_Management
                        ? value?.Pending_Senior_Management
                        : 0}
                    </h4>
                    <p className="text-muted">Pending Senior Management</p>
                  </div>
                </div>               

                <div className="media mt-4 dash-main-border pb-2">
                  <div className="avatar-sm mb-3 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-check-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Completed ? value?.Completed : 0}
                    </h4>
                    <p className="text-muted">Completed</p>
                  </div>
                </div>

                <div className="media mt-4 dash-main-border pb-2">
                  <div className="avatar-sm mb-3 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-remove-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Rejected ? value?.Rejected : 0}
                    </h4>
                    <p className="text-muted">Rejected</p>
                  </div>
                </div>

                <div className="media mt-4">
                  <div className="avatar-sm mb-2 mt-2">
                    <span className="avatar-title rounded-circle bg-white shadow">
                      <i className="mdi mdi-file-check-outline text-primary font-size-18"></i>
                    </span>
                  </div>
                  <div className="media-body ps-3">
                    <h4 className="font-size-20">
                      {value?.Open ? value?.Open : 0}
                    </h4>
                    <p className="text-muted mb-0">Open</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
