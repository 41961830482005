import Swal from 'sweetalert2';
import api from '../../helpers/api';

export function showDeleteConfirmation() {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#564ab1",
    cancelButtonColor: "#f14e4e",
    confirmButtonText: "Yes, delete it!"
  });
}

export function showEditConfirmation() {
  Swal.fire({
    title: "Are you sure you want to edit this?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#564ab1",
    cancelButtonColor: "#f14e4e",
    confirmButtonText: "Yes, edit it!"
  });
}

export function showSubmitPRConfirmation() {
    Swal.fire({
      title: "Are you sure to submit?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#564ab1",
      cancelButtonColor: "#f14e4e",
      confirmButtonText: "Yes, submit it!"
    })
}

export function showErrorAlert(title, text, icon){
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        footer: "Contact your admin to solve this issue",
      });
}
