import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { metisMenu } from "metismenu";
import { AuthContext } from "../helpers/authContext";

export default function LeftSideBar() {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const initializeSidebar = () => {
      $("#side-menu").metisMenu();

      $("#vertical-menu-btn").on("click", function (e) {
        e.preventDefault();
        $("body").toggleClass("sidebar-enable");
        if (992 <= $(window).width()) {
          $("body").removeClass("vertical-collpsed");
        }
      });

      $("body,html").click(function (e) {
        const verticalMenuBtn = $("#vertical-menu-btn");
        if (
          !verticalMenuBtn.is(e.target) &&
          verticalMenuBtn.has(e.target).length === 0 &&
          !e.target.closest("div.vertical-menu")
        ) {
          $("body").removeClass("sidebar-enable");
        }
      });

      // Return cleanup function
      return () => {
        $("#vertical-menu-btn").off("click");
      };
    };

    initializeSidebar();
    // sidebarMenuLink();
  }, []);

  useEffect(() => {
    const scrollSidebarMenu = () => {
      const sidebarMenu = $("#sidebar-menu");

      if (sidebarMenu.length > 0) {
        const activeItems = sidebarMenu.find(".mm-active .active");

        if (activeItems.length > 0) {
          const topOffset = 300;
          const targetOffset = activeItems.offset().top;

          if (targetOffset > topOffset) {
            const scrollTarget = targetOffset - topOffset;

            $(".vertical-menu .simplebar-content-wrapper").animate(
              { scrollTop: scrollTarget },
              "slow"
            );
          }
        }
      }

      // Return cleanup function
      return () => {
        $("#vertical-menu-btn").off("click");
      };
    };

    scrollSidebarMenu();
  }, []);

  const sidebarMenuNavLink = () => {
    $("#sidebar-menu a").each(function () {
      const currentURL = window.location.href.split(/[?#]/)[0];
      if (this.href === currentURL) {
        $(this).addClass("active");
        $(this).parent().addClass("mm-active");
        $(this).parent().parent().addClass("mm-show");
        $(this).parent().parent().prev().addClass("mm-active");
        $(this).parent().parent().parent().addClass("mm-active");
        $(this).parent().parent().parent().parent().addClass("mm-show");
        $(this)
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .parent()
          .addClass("mm-active");
      }
    });
  };

  // render menu item - for besides admin
  const besidesAdminMenuItem = () => {
    return (
      <>
        <li>
          <NavLink to="/index" className="waves-effect">
            <i className="dripicons-home"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/list-pr" className=" waves-effect">
            <i className="dripicons-checklist"></i>
            <span>List of PR</span>
          </NavLink>
        </li>
      </>
    );
  };

  // render menu item - for admin
  const adminMenuItem = () => {
    return (
      <>
        <li>
          <NavLink to="/index" className="waves-effect">
            <i className="dripicons-home"></i>
            <span>Dashboard</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/user-management" className="waves-effect">
            <i className="mdi mdi-badge-account-horizontal-outline"></i>
            <span>User Management</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/admin/project-management" className="waves-effect">
            <i className="mdi mdi-briefcase-outline"></i>
            <span>Project Management</span>
          </NavLink>
        </li>
      </>
    );
  };

  return (
    <div className="vertical-menu">
      <div className="h-100">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {currentUser?.user_role_id === 1
              ? adminMenuItem()
              : besidesAdminMenuItem()}
          </ul>
        </div>
      </div>
    </div>
  );
}
