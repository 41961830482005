import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { AuthContext } from "../helpers/authContext";
import API from "../helpers/baseApi";
import { showErrorAlert } from "./ui/SweetAlert";

export default function Navbar() {
  const { accessToken, setAccessToken, currentUser, setCurrentUser, theme, toggleTheme } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const handleClickSideBar = () => {
    handleSidebarToggle();
    closeSidebarOnOutsideClick();
  };

  // click button to hide the side bar, click again to display
  const handleSidebarToggle = () => {
    $("#vertical-menu-btn").on("click", (e) => {
      e.preventDefault();
      $("body").toggleClass("sidebar-enable");
      if (992 <= $(window).width()) {
        $("body").toggleClass("vertical-collpsed");
      } else {
        $("body").removeClass("vertical-collpsed");
      }
    });
  };

  const closeSidebarOnOutsideClick = () => {
    $("body,html").click((e) => {
      const target = $("#vertical-menu-btn");
      if (
        !target.is(e.target) &&
        target.has(e.target).length === 0 &&
        !e.target.closest("div.vertical-menu")
      ) {
        $("body").removeClass("sidebar-enable");
      }
    });
  };

  // click search icon, then display search bar, click 'x' to close
  useEffect(() => {
    const handleToggleSearchClick = () => {
      const target = $(".toggle-search").data("target");
      target && $(target).toggleClass("open");
    };

    // Attach the click event listener to toggle-search
    $(".toggle-search").on("click", handleToggleSearchClick);

    // Cleanup function
    return () => {
      // Remove event listener for toggle-search
      $(".toggle-search").off("click", handleToggleSearchClick);
    };
  }, []);

  // fullscreen - if full screen button clicked, display console log & enter full screen, if click esc, exit from full screen
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        console.log("Full screen size");
        $("body").removeClass("fullscreen-enable");
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);

    // Cleanup function
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  // display right bar after click the settings button
  useEffect(() => {
    const handleRightBarToggle = (e) => {
      $("body").toggleClass("right-bar-enabled");
    };

    // Attach the click event listener
    $(".right-bar-toggle").on("click", handleRightBarToggle);

    // Cleanup function to remove the event listener
    return () => {
      $(".right-bar-toggle").off("click", handleRightBarToggle);
    };
  });

  // close the right bar after click outside the right bar
  useEffect(() => {
    const handleBodyClick = (e) => {
      if (!($(e.target).closest(".right-bar-toggle, .right-bar").length > 0)) {
        $("body").removeClass("right-bar-enabled");
      }
    };

    // Attach the click event listener
    $(document).on("click", "body", handleBodyClick);

    // Cleanup function to remove the event listener
    return () => {
      $(document).off("click", "body", handleBodyClick);
    };
  });

  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedMode = sessionStorage.getItem("is_visited");
    if (storedMode === "dark-mode-switch") {
      setIsDarkMode(true);
    } else {
      setIsDarkMode(false);
    }
  }, []);

  // Logout
  const logout = async () => {
    try {
      await API.get("/api/csrf-cookie").then(async () => {
        const response = await API.post(
          "/api/user/logout",
          {},
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Custom-Header": "value",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          localStorage.removeItem("accessToken");
          setCurrentUser(null);
          setAccessToken("");
          navigate("/auth-login");
        }
      });
    } catch (error) {
      showErrorAlert(
        "Oops...",
        "Something went wrong! Please try again",
        "error"
      );
    }
  };

  return (
    <header id="page-topbar" data-theme={theme}>
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <Link to="/index" className="logo logo-dark">
              <span className="logo-lg">
                <img
                  src="https://www.aexis.com.my/public/images/logo.png"
                  alt=""
                  height="20"
                />
              </span>
            </Link>

            <Link to="/index" className="logo logo-light">
              <span className="logo-lg">
                <img
                  src="https://www.aexis.com.my/public/images/logo.png"
                  alt=""
                  height="20"
                />
              </span>
            </Link>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-24 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={handleClickSideBar}
          >
            <i className="mdi mdi-menu"></i>
          </button>
        </div>

        <div className="d-flex">                      
            <button
              type="button"
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={toggleTheme}
            >
              {theme === 'dark' ? <i className="mdi mdi-weather-sunny font-size-20"></i> : <i className="mdi mdi-moon-waning-crescent font-size-20"></i>}
              
            </button>         

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="mdi mdi-account-circle-outline font-size-20"></i>
              {!currentUser ? (
                <span className="d-none d-xl-inline-block ms-1"></span>
              ) : (
                <span className="d-none d-xl-inline-block ms-1">
                  {currentUser.user_fullname}
                </span>
              )}
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              <Link
                className="dropdown-item text-danger"
                onClick={() => logout()}
              >
                <i className="mdi mdi-power font-size-16 align-middle me-1 text-danger"></i>{" "}
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
